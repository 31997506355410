import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Config from '../../commons/config/Config.js'
import Switch from "react-switch"
import $ from 'jquery'
import 'bootstrap4-duallistbox'
import 'bootstrap4-duallistbox/dist/bootstrap-duallistbox.css'
import Validator from '../../commons/validation/Validator.js'
import Security from '../../commons/security/Security.js'
import AjaxHandler from '../../commons/ajax/AjaxHandler.js'
import ConfigBusiness from '../../commons/config/ConfigBusiness.js'
import Dialog from '../../commons/dialog/Dialog.js'
import Loading from '../ui/Loading.js'
import Select from 'react-select'
import 'react-select/dist/react-select.css'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import 'moment/min/locales'
import 'react-datepicker/dist/react-datepicker.css'
//import Switch from "react-switch"
import DropzoneComponent from 'react-dropzone-component/dist/react-dropzone'
import 'react-dropzone-component/styles/filepicker.css'
import 'dropzone/dist/min/dropzone.min.css'
import '../../assets/css/vec-dropzone.css'
import ChoferesGrid from './ChoferesGrid'
import CecosGrid from './CecosGrid'
import LugarDeGuardaGrid from './LugarDeGuardaGrid'

import MovilesAbmAdjuntos from './MovilesAbmAdjuntos.js';
import swal2 from 'sweetalert2'
import swal from 'sweetalert'

import DireccionAutocompletar from '../direccionAutocompletar/DireccionAutocompletar'
import {Link } from 'react-router-dom'

import MovilesAbmGPS from './MovilesAbmGPS.js'
import SelectWithIcons from '../selectWithIcons/SelectWithIcons.js'
import { TIPOS_UNIDAD_MEDIDOR as UNIDAD_MEDIDOR } from '../unidadMedidor/constants.js'

import {FormattedMessage, injectIntl} from 'react-intl';
import backendStrings from '../../lang/backendStrings.js';
import { TIPOS_TRACCION } from '../tiposTraccion/constants.js'
import { atributosDinamicosRequeridosFaltantes, castValoresDinamicos } from '../atributoDinamico/helpers.js'
import AtributoDinamicoInstancia from '../atributoDinamico/AtributoDinamicoInstancia.js'

import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import swalImport from 'sweetalert'
import CambioCentroCostoMovil from "./CambioCentroCostoMovil";
import CambioDominioMovil from "./CambioDominioMovil";
import * as Yup from 'yup';
import { validateForm, validateFormAll } from '../../commons/validation/ValidateForm.js'
import HistoricoDatosGeneralesGrid from './HistoricoDatosGeneralesGrid'
import MovilesCambioTipologia from './MovilesCambioTipologia';
import CambioTarjetaCombustible from './CambioTarjetaCombustible';
import HistoricoAdmGrid from './HistoricoAdmGrid';


class MovilesAbm extends Component {
    constructor(props) {
        super(props);
        this.ajaxHandler = new AjaxHandler();
        moment.locale('es');
        this.state = {
            redirectTo: null,
            props: this.props,
            tipoModal: null,
            tickets: [],
            unidadMedidorIdOriginal: null,
            formData: {
                id: 0,
                unidad: '',
                dominio: '',
                base: null,
                activo: true,
                marca: null,
                modelo: null,
                chasis: '',
                motor: '',
                imei: '',
                color: '',
                anio: 0,
                valorAdquisicion: 0,
                valorAmortizacion: 0,
                valorContable: 0,
                valorAlquiler: 0,
                pesoCargaTotalAutorizado: 0,
                pesoCargaMaxima: 0,
                volumenAreaCarga: 0,
                cantidadCompartimentos: 0,
                alturaEspacioCarga: 0,
                anchoEspacioCarga: 0,
                longitudEspacioCarga: 0,
                tipo: null,
                planPreventivo: null,
                planVencimiento: null,
                fechaAlta: null,
                fechaBaja: null,
                fechaFinGarantia: null,
                fechaFinContrato: null,
                valorPoliza: 0,
                responsable1: null,
                responsable2: null,
                supervisor: null,
                temporal: null,
                centroCostos: null,
                cebe: null,
                poliza: '',
                lugarGuarda: '',
                certificadoVtv: '',
                telepeaje: '',
                ypfRuta: '',
                kmActual: null,
                dnrpaSeccional: null,
                activoFijo: '',
                equipo: '',
                nroTitulo: '',
                observaciones: '',
                adjuntos: [],
                estado: '',
                movilCombustibleTipo: null,
                traccion: '',
                titular: null,
                proveedorGps: null,
                companiaOrigen: '',
                dnrpa: 0,
                guardaEnBase: 1,
                equipamientos: [],
                gps_model: null,
                gps_model_id: null,
                force_avl_odometer: false,
                unidadMedidorId: null,
                tipologia_posicion: null,
                fechaInicioOperacion: null,
                funcion: null,
                buscar_infracciones: 1,
                rendimientoPropio: false,
                rendimientoEsperado: '',
                tanquePropio: false,
                capacidadTanque: '',
                estadoErp: '',
                valores_dinamicos: [],
                claseEloquent: '',
                division: null
            },
            basellantas: null,
            showModalTipologiaLlanta: false,
            tipologiaOriginal: null,
            tipologiaSelect: null,
            llantasDeMovil: [],
            clases: [],
            bases: [],
            marcas: [],
            modelos: [],
            planesVencimiento: [],
            movilCombustibleTipos: [],
            centrosCostos: [],
            cebes: [],
            errors: [],
            estados: [],
            estadosActivos: [],
            estadosInactivos: [],
            disableAvlFields: false,
            dnrpaSeccionales: [],
            tipologias: [],
            loading: false,
            basesLoading: false,
            marcasLoading: false,
            modelosLoading: false,
            centrosCostosLoading: false,
            dnrpaSeccionalesLoading: false,
            cebesLoading: false,
            estadosLoading: false,
            combustiblesLoading: false,
            traccionLoading: false,
            planesVencimientosLoading: false,
            planesPreventivosLoading: false,
            unidadMedidorIdLoading: false,
            viewColor: false,
            companiaOrigenInputType: 'text',
            companiasOrigen: [],
            companiasLoading: false,
            titularInputType: 'select',
            titulares: [],
            titularesLoading: false,
            equipamientosDisponibles: [],
            kmTemporal: 0,
            funciones: [],
            valores_dinamicos: [],
            proveedoresGps: [],
            proveedoresGpsLoading: false,
            schema: null,
            validationErrors: {},
            validationDirty: {},
            divisiones: [],
            dominioGuardado: '',
            arrayDisponibles: [],
            arrayNodisponibles: [],
            estadoClasificadorText: "",
            tarjetaCombustibleOrig: ""
        };

        this.handleGuardaPersonalizada = this.handleGuardaPersonalizada.bind(this);
        this.handleChangeDireccion = this.handleChangeDireccion.bind(this);
        this.handleKmActualChange = this.handleKmActualChange.bind(this);
        this.handleInputFormChange = this.handleInputFormChange.bind(this);
        this.handleFormChangeSelectString = this.handleFormChangeSelectString.bind(this);
        this.handleFormChangeSelectTitular = this.handleFormChangeSelectTitular.bind(this);
        this.handleFormChangeSelectObject = this.handleFormChangeSelectObject.bind(this);
        this.handleFormChangeTipologia = this.handleFormChangeTipologia.bind(this);
        this.handleDatePickerFormChange = this.handleDatePickerFormChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.setEstadoActivo = this.setEstadoActivo.bind(this);
        this.handleInputSeparadoFormChange = this.handleInputSeparadoFormChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getPlanPreventivos = this.getPlanPreventivos.bind(this);
        this.handlePlanPreventivoChange = this.handlePlanPreventivoChange.bind(this);
        this.handleFormChangeSelectFuncion = this.handleFormChangeSelectFuncion.bind(this);
        this.getBackendPersonasPorBase = this.getBackendPersonasPorBase.bind(this);
        this.handleChangeAtributoDinamico = this.handleChangeAtributoDinamico.bind(this);
        this.validateDomainChange = this.validateDomainChange.bind(this);
        this.handleChangeTarjetaCombustible = this.handleChangeTarjetaCombustible.bind(this);
        this.initFileUpload();
    }

  componentWillMount() {
    if((Security.hasPermission('MOVILES_CREAR') && this.state.props.action === 'ADD') ||
       (Security.hasPermission('MOVILES_MODIFICAR') && this.state.props.action === 'EDIT') ||
       (Security.hasPermission('MOVILES_VISUALIZAR') && this.state.props.action === 'VIEW')) {
      this.ajaxHandler.subscribe(this);
      this.initForm();
    } else {
      this.setState({
        redirectTo: '/error'
      });
    }
  }

  componentWillUnmount() {
    this.ajaxHandler.unsubscribe();
  }

	initForm() {
    let component = this;
    let viewColor = ConfigBusiness.get('moviles.color.habilitado') === 'true' ? true : false;
    let viewYpfRuta = ConfigBusiness.get('moviles.ypfRuta.habilitado') === 'true' ? true : false;
    let viewPoliza = ConfigBusiness.get('moviles.poliza.habilitado') === 'true' ? true : false;
    let viewLugarGuarda = ConfigBusiness.get('moviles.lugarGuarda.habilitado') === 'true' ? true : false;
    let viewCebe = ConfigBusiness.get('moviles.cebe.habilitado') === 'true' ? true : false;
    let viewCentroCostos = ConfigBusiness.get('moviles.centroCostos.habilitado') === 'true' ? true : false;
    let labelTitular = ConfigBusiness.get('moviles.titular.label') ? ConfigBusiness.get('moviles.titular.label') : '';
    let validaPatentes = ConfigBusiness.get('moviles.patente.validaPatentes') == 'true' ? true : false;
    let regex = new RegExp(ConfigBusiness.get('moviles.patente.patentesValidas'));
    let validaChasis = ConfigBusiness.get('moviles.chasis.validaChasis') == 'true' ? true : false;
    let regexChasis = new RegExp(ConfigBusiness.get('moviles.chasis.chasisValida'));
    let datoRequerido = this.props.intl.formatMessage({ id: 'validation.required', defaultMessage: 'Dato requerido' });
    let invalidNumber = this.props.intl.formatMessage({ id: 'validation.invalid_number', defaultMessage: 'Número inválido' });
    let invalidDate = this.props.intl.formatMessage({ id: 'validation.invalid_date', defaultMessage: 'Fecha inválida' })

    Yup.addMethod(Yup.string, 'numberEmptyNullable', function(invalidNumber){
      return this.matches(/^([-+]?[0-9]*\.?[0-9]+)?$/, invalidNumber).nullable();
    });

    Yup.addMethod(Yup.string, 'numberEmpty', function(invalidNumber){
      return this.matches(/^([-+]?[0-9]*\.?[0-9]+)?$/, invalidNumber);
    });

    Yup.addMethod(Yup.string, 'numberRequired', function(invalidNumber){
      return this.matches(/^[-+]?[0-9]*\.?[0-9]+$/, invalidNumber).required(datoRequerido).nullable()
    });

    let dominio = (validaPatentes && regex !== '') ? {
      dominio : Yup.string()
        .required(this.props.intl.formatMessage({ id: 'validation.required', defaultMessage: 'Dato requerido' }))
        .transform(value => { if(!regex.test(value) && value !== '' ) {
            throw new Yup.ValidationError(
              new Yup.ValidationError(this.props.intl.formatMessage({ id: 'validation.dominio_invalid', defaultMessage: 'Patente inválida' }), value, 'dominio', 'is-valid')
            );
          } else { return value }
        })
    } : {
      dominio: Yup.string().required(datoRequerido)
    };

    let chasis = (ConfigBusiness.get('moviles.chasis.obligatorio') === 'true') ? 
      (
        (validaChasis && regexChasis !== '') ? 
          {
            chasis : Yup.string()
              .required(this.props.intl.formatMessage({ id: 'validation.required', defaultMessage: 'Dato requerido' }))
              .transform(value => { if(!regexChasis.test(value) && value !== '' ) {
                  throw new Yup.ValidationError(
                    new Yup.ValidationError(
                      this.props.intl.formatMessage({
                        id: 'validation.chasis_invalid',
                        defaultMessage: 'El VIN debe contener 17 caracteres | El VIN no cumple con un formato válido | El VIN posee caracteres alfabéticos en minúscula'
                      }),
                      value,
                      'chasis',
                      'is-valid'
                    )
                  );
                } else { return value }
              })
          }
        :
          {
            chasis: Yup.string().required(datoRequerido)
          }
      )
    : (
        (validaChasis && regexChasis !== '') ? 
          {
            chasis : Yup.string()
              .transform(value => { if(!regexChasis.test(value) && value !== '' ) {
                  throw new Yup.ValidationError(
                    new Yup.ValidationError(
                      this.props.intl.formatMessage(
                        { id: 'validation.chasis_invalid',
                          defaultMessage: 'El VIN debe contener 17 caracteres | El VIN no cumple con un formato válido | El VIN posee caracteres alfabéticos en minúscula'
                        }),
                      value,
                      'chasis',
                      'is-valid'
                  )
                  );
                } else { return value }
              })
          }
        : {}
      );

    let estado = ConfigBusiness.get('moviles.estado.habilitado') === 'true' ? {
      estado: Yup.string().required(datoRequerido).nullable() } : {};

    let base = ConfigBusiness.get('moviles.base.habilitado') === 'true' ? {
      base: Yup.object().required(datoRequerido).nullable() } : {};

    let marca = ConfigBusiness.get('moviles.marca.obligatorio') === 'true' ? {
      marca: Yup.object().required(datoRequerido).nullable() } : {};

    let modelo = ConfigBusiness.get('moviles.modelo.obligatorio') === 'true' ? {
      modelo: Yup.object().required(datoRequerido).nullable() } : {};

    // let chasis = (ConfigBusiness.get('moviles.chasis.obligatorio') === 'true') ? {
    //   chasis: Yup.string().required(datoRequerido) } : {};

    let motor = ConfigBusiness.get('moviles.motor.obligatorio') === 'true' ? {
      motor: Yup.string().nullable().required(datoRequerido) } : {};

    let anio = ConfigBusiness.get('moviles.anio.obligatorio') === 'true' ? {
      anio: Yup.string()
      .matches(/^[0-9]+$/, this.props.intl.formatMessage({ id: 'validation.numeric', defaultMessage: 'Solo números' }))
      .min(4, this.props.intl.formatMessage({ id: 'validation.year_yyyy', defaultMessage: 'Ingrese 4 dígitos' }))
      .max(4, this.props.intl.formatMessage({ id: 'validation.year_yyyy', defaultMessage: 'Ingrese 4 dígitos' }))
      .required(datoRequerido)
    } : {
      anio: Yup.string()
      .matches(/^(\s*|\d+)$/, this.props.intl.formatMessage({ id: 'validation.numeric', defaultMessage: 'Solo números' }))
      .min(4, this.props.intl.formatMessage({ id: 'validation.year_yyyy', defaultMessage: 'Ingrese 4 dígitos' }))
      .max(4, this.props.intl.formatMessage({ id: 'validation.year_yyyy', defaultMessage: 'Ingrese 4 dígitos' }))
      .nullable()
    }

    let traccion = ConfigBusiness.get('moviles.traccion.obligatorio') === 'true' ? {
      traccion: Yup.string().required(datoRequerido) } : {};

    let planPreventivo = ConfigBusiness.get('moviles.planMantPreventivo.obligatorio') === 'true' ? {
      planPreventivo: Yup.object().required(datoRequerido).nullable() } : {};

    let movilCombustibleTipo = ConfigBusiness.get('moviles.movilCombustibleTipo.obligatorio') === 'true' ? {
      movilCombustibleTipo: Yup.object().required(datoRequerido).nullable() } : {};

    let dnrpaSeccional = ConfigBusiness.get('moviles.dnrpaSeccional.obligatorio') === 'true' ? {
      dnrpaSeccional: Yup.object().required(datoRequerido).nullable() } : {};

    let titular = (ConfigBusiness.get('moviles.titular.habilitado') === 'true' && ConfigBusiness.get('moviles.titular.obligatorio') === 'true') ? {
      titular: Yup.object().required(datoRequerido).nullable() } : {};

    let companiaOrigen = (ConfigBusiness.get('moviles.companiaOrigen.habilitado') === 'true' && ConfigBusiness.get('moviles.companiaOrigen.obligatorio') === 'true') ? {
      companiaOrigen: Yup.string().required(datoRequerido).nullable() } : {};

    let fechaAlta = ConfigBusiness.get('moviles.fechaAlta.obligatorio') === 'true' ? {
      fechaAlta: Yup.date().max(moment(), invalidDate).required(datoRequerido).nullable() } : {};

    let fechaFinGarantia = ConfigBusiness.get('moviles.fechaFinGarantia.obligatorio') === 'true' ? {
      fechaFinGarantia: Yup.date().min(Yup.ref('fechaAlta'), invalidDate).required(datoRequerido).nullable() } : {};

    let centroCostos = (viewCentroCostos && ConfigBusiness.get('moviles.centroCostos.obligatorio') === 'true') ? {
      centroCostos: Yup.object().required(datoRequerido).nullable() } : {};

    let cebe = (viewCebe && ConfigBusiness.get('moviles.cebe.obligatorio') === 'true') ? {
      cebe: Yup.object().required(datoRequerido).nullable() } : {};

    let valorAdquisicion = ConfigBusiness.get('moviles.valorAdquisicion.obligatorio') === 'true' ? {
      valorAdquisicion: Yup.string().numberRequired(invalidNumber) } : { valorAdquisicion: Yup.string().numberEmpty(invalidNumber) };

    let valorAmortizacion = ConfigBusiness.get('moviles.valorAmortizacion.obligatorio') === 'true' ? {
      valorAmortizacion: Yup.string().numberRequired(invalidNumber) } : { valorAmortizacion: Yup.string().numberEmpty(invalidNumber) };

    let valorContable = ConfigBusiness.get('moviles.valorContable.obligatorio') === 'true' ? {
      valorContable: Yup.string().numberRequired(invalidNumber) } : { valorContable: Yup.string().numberEmpty(invalidNumber) };

    let valorAlquiler = ConfigBusiness.get('moviles.valorAlquiler.obligatorio') === 'true' ? {
      valorAlquiler: Yup.string().numberRequired(invalidNumber) } : { valorAlquiler: Yup.string().numberEmpty(invalidNumber) };

    let pesoCargaTotalAutorizado = ConfigBusiness.get('moviles.pesoCargaTotalAutorizado.obligatorio') === 'true' ? {
      pesoCargaTotalAutorizado: Yup.string().numberRequired(invalidNumber) } : { pesoCargaTotalAutorizado: Yup.string().numberEmptyNullable(invalidNumber) };

    let pesoCargaMaxima = ConfigBusiness.get('moviles.pesoCargaMaxima.obligatorio') === 'true' ? {
      pesoCargaMaxima: Yup.string().numberRequired(invalidNumber) } : { pesoCargaMaxima: Yup.string().numberEmptyNullable(invalidNumber) };

    let volumenAreaCarga = ConfigBusiness.get('moviles.volumenAreaCarga.obligatorio') === 'true' ? {
      volumenAreaCarga: Yup.string().numberRequired(invalidNumber) } : { volumenAreaCarga: Yup.string().numberEmptyNullable(invalidNumber) };

    let cantidadCompartimentos = ConfigBusiness.get('moviles.cantidadCompartimentos.obligatorio') === 'true' ? {
      cantidadCompartimentos: Yup.string().numberRequired(invalidNumber) } : { cantidadCompartimentos: Yup.string().numberEmptyNullable(invalidNumber) };

    let alturaEspacioCarga = ConfigBusiness.get('moviles.alturaEspacioCarga.obligatorio') === 'true' ? {
      alturaEspacioCarga: Yup.string().numberRequired(invalidNumber) } : { alturaEspacioCarga: Yup.string().numberEmptyNullable(invalidNumber) };

    let anchoEspacioCarga = ConfigBusiness.get('moviles.anchoEspacioCarga.obligatorio') === 'true' ? {
      anchoEspacioCarga: Yup.string().numberRequired(invalidNumber) } : { anchoEspacioCarga: Yup.string().numberEmptyNullable(invalidNumber) };

    let longitudEspacioCarga = ConfigBusiness.get('moviles.longitudEspacioCarga.obligatorio') === 'true' ? {
      longitudEspacioCarga: Yup.string().numberRequired(invalidNumber) } : { longitudEspacioCarga: Yup.string().numberEmptyNullable(invalidNumber) };

    let kmActual = ConfigBusiness.get('moviles.kmActual.obligatorio') === 'true' ? {
      kmActual: Yup.string().numberRequired(invalidNumber) } : { kmActual: Yup.string().numberEmptyNullable(invalidNumber) };

    let schema = Yup.object().shape( {
      ...dominio,
      ...estado,
      ...base,
      ...marca,
      ...modelo,
      ...chasis,
      ...motor,
      ...anio,
      ...traccion,
      ...planPreventivo,
      ...movilCombustibleTipo,
      ...dnrpaSeccional,
      ...titular,
      ...companiaOrigen,
      ...fechaAlta,
      ...fechaFinGarantia,
      ...centroCostos,
      ...cebe,
      ...valorAdquisicion,
      ...valorAmortizacion,
      ...valorContable,
      ...valorAlquiler,
      ...pesoCargaTotalAutorizado,
      ...pesoCargaMaxima,
      ...volumenAreaCarga,
      ...cantidadCompartimentos,
      ...alturaEspacioCarga,
      ...anchoEspacioCarga,
      ...longitudEspacioCarga,
      ...kmActual,
      valorPoliza: Yup.string().numberEmptyNullable(invalidNumber),
      unidadMedidorId: Yup.string().required(datoRequerido),
      rendimientoPropio: Yup.boolean().notRequired(),
      rendimientoEsperado: Yup.number().when('rendimientoPropio', {
        is: true,
        then: Yup.number().transform(value => (isNaN(value) ? undefined : value)).positive(invalidNumber).required(datoRequerido),
        otherwise: Yup.number().transform(value => (isNaN(value) ? undefined : value)).nullable()
      }),
      tanquePropio: Yup.boolean().notRequired(),
      capacidadTanque: Yup.number().when('tanquePropio', {
        is: true,
        then: Yup.number().transform(value => (isNaN(value) ? undefined : value)).positive(invalidNumber).required(datoRequerido),
        otherwise: Yup.number().transform(value => (isNaN(value) ? undefined : value)).nullable()
      }),
    });

		this.setState({
      viewCebe: viewCebe,
      viewColor: viewColor,
      viewYpfRuta: viewYpfRuta,
      viewPoliza: viewPoliza,
      viewLugarGuarda: viewLugarGuarda,
      viewCentroCostos: viewCentroCostos,
      labelTitular: labelTitular,
      schema: schema,
    });

    if(ConfigBusiness.get('moviles.clasificador.habilitado') === "true"){
      let disponible = ConfigBusiness.get('moviles.clasificador.disponible');
      disponible = disponible ? disponible.split(',') : [];

      let nodisponible = ConfigBusiness.get('moviles.clasificador.nodisponible');
      nodisponible = nodisponible ? nodisponible.split(',') : null;
      this.setState({
        arrayDisponibles: disponible,
        arrayNodisponibles: nodisponible
      });
    }

    if(this.state.props.action === 'VIEW' || this.state.props.action === 'EDIT') this.loadFormData();
    if(this.state.props.action === 'EDIT') this.loadTicketsData();

    if(this.state.props.action === 'ADD' || this.state.props.action === 'EDIT') {
      this.setState({
        basesLoading: true,
        marcasLoading: true,
        centrosCostosLoading: true,
        cebesLoading: true,
        estadosLoading: true,
        combustiblesLoading: true,
        loading: this.state.props.action === 'EDIT',
        unidadMedidorIdLoading: true,
        planesPreventivosLoading: true,
        planesVencimientosLoading: true,
        companiasLoading: true,
        titularesLoading: true,
        proveedoresGpsLoading: true
      });

  		Promise.all([
        this.ajaxHandler.getJson('/bases/select'),
        this.ajaxHandler.getJson('/marcas/select'),
        this.ajaxHandler.getJson('/centros-costos/select'),
        null,
        ConfigBusiness.get('pointer.disableFields') === 'true',
        ConfigBusiness.get('moviles.cebe.habilitado') === 'true' ? this.ajaxHandler.getJson('/cebes/select') : null,
        ConfigBusiness.get('moviles.estados'),
        ConfigBusiness.get('moviles.estadosActivos'),
        ConfigBusiness.get('moviles.estadosInactivos'),
        this.ajaxHandler.getJson('/plan-mantenimiento-preventivos/PREVENTIVO/select'),
        ConfigBusiness.get('moviles.companiaOrigen.inputType'),
        ConfigBusiness.get('moviles.companiaOrigen.valores'),
        ConfigBusiness.get('moviles.titular.inputType'),
        this.ajaxHandler.getJson('/movil-titulares/select'),
        this.ajaxHandler.getJson('/dnrpa-seccionales/competencia/AUTOMOTOR/select'),
        this.ajaxHandler.getJson('/plan-mantenimiento-preventivos/VENCIMIENTO/select'),
				this.ajaxHandler.getJson('/equipamiento'),
        this.ajaxHandler.getJson('/moviles/moviles/tipologias'),
        this.ajaxHandler.getJson('/funciones'),
        (Security.hasPermission('LLANTAS_MODELOS_VISUALIZAR') || Security.hasPermission('LLANTAS_MODELOS_LISTAR'))
            ? this.ajaxHandler.getJson('/llanta-modelo-clases')
            : null,
        this.ajaxHandler.getJson('/gerenciadores/tipo/4/select'),
        this.ajaxHandler.getJson('/movil-combustible-tipos/select'),
        this.ajaxHandler.getJson('/divisiones/select')
      ]).then((data) => {
        component.setState({
          basesLoading: false,
          marcasLoading: false,
          centrosCostosLoading: false,
          cebesLoading: false,
          estadosLoading: false,
          combustiblesLoading: false,
          unidadMedidorIdLoading: false,
          planesPreventivosLoading: false,
          planesVencimientosLoading: false,
          companiasLoading: false,
          titularesLoading: false,
          selectDisable: false,
          proveedoresGpsLoading: false
        });
        let bases = data[0] ? data[0] : [];
        let marcas = data[1] ? data[1] :[];
        let centrosCostos = data[2] ? data[2] : [];
        let movilCombustibleTipos = data[21] ? data[21] : [];
        let cebes = viewCebe ? data[5] : [];
        let estados = data[6] ? data[6].split(',').map(e =>({value: e, label: e})) : [];
        let estadosActivos = data[7] ? data[7].split(',') : [];
        let estadosInactivos = data[8] ? data[8].split(',') : [];
        let planesPreventivo = data[9] ? data[9] : [];
        let companiaOrigenInputType = data[10] ? data[10] : '';
        let companiasOrigen = data[11] ? data[11].split(',').map(e =>({value: e, label: e})) : [];
        //let titularInputType = data[12] ? data[12] : '';
        // let titularInputType = 'select';
        let titulares = data[13] ? data[13] : [];
        let dnrpaSeccionales = data[14] ? data[14] : [];
				let planesVencimiento = data[15] ? data[15] : [];
        let equipamientosDisponibles = data[16] ? data[16] : [];
        let tipologias = data[17] ? data[17] : [];
        let funciones = data[18] ? data[18] : [];
        let clases = data[19] ? data[19] : [];
        let divisiones = data[22] ? data[22] : [];
				$(this.refs.duallistboxequipamientos).bootstrapDualListbox({
					moveOnSelect: false,
					moveAllLabel: this.props.intl.formatMessage({ id: 'movilesAbm.associated_equipment.title_mover_todos', defaultMessage: 'Mover todos' }),
					removeAllLabel: this.props.intl.formatMessage({ id: 'movilesAbm.associated_equipment.title_remover_todos', defaultMessage: 'Remover todos' }),
					moveSelectedLabel: this.props.intl.formatMessage({ id: 'movilesAbm.associated_equipment.title_mover_seleccionados', defaultMessage: 'Mover seleccionados' }),
					removeSelectedLabel: this.props.intl.formatMessage({ id: 'movilesAbm.associated_equipment.title_remover_seleccionados', defaultMessage: 'Remover seleccionados' }),
					infoText: false,
					filterPlaceHolder: "Filtro",
					nonSelectedListLabel: this.props.intl.formatMessage({ id: 'movilesAbm.associated_equipment.label_remover_seleccionados', defaultMessage: 'Equipamientos Disponibles:' }),
					selectedListLabel: this.props.intl.formatMessage({ id: 'movilesAbm.associated_equipment.label_equipamientos_asignados:', defaultMessage: 'Equipamientos Asignados:' })
				});
        let proveedoresGps = data[20] ? data[20] : [];

				$(this.refs.duallistboxequipamientos).change((e) => {
						let formDataCopy = this.state.formData;
						formDataCopy.equipamientos = $(this.refs.duallistboxequipamientos).val();
						this.setState({
								formData: formDataCopy
						}, () => {
							$(this.refs.duallistboxequipamientos).bootstrapDualListbox('refresh', true);
						});
				});

        let disableAvlFields = false
        if(ConfigBusiness.get('avl_service.service') === 'pointer') {
          disableAvlFields = ConfigBusiness.get('pointer.disableFields') === 'true' ? true: false
        }
        if(ConfigBusiness.get('avl_service.service') === 'geotab') {
          disableAvlFields = ConfigBusiness.get('geotab.disableFields') === 'true' ? true: false
        }

  			component.setState({
          disableAvlFields: disableAvlFields,
          bases: bases,
          marcas: marcas,
          centrosCostos: centrosCostos,
          movilCombustibleTipos: movilCombustibleTipos,
          cebes: cebes,
          estados: estados,
          estadosActivos: estadosActivos,
          estadosInactivos: estadosInactivos,
          planesPreventivo: planesPreventivo,
          // titularInputType: titularInputType,
          titulares: titulares,
          companiaOrigenInputType: companiaOrigenInputType,
          companiasOrigen: companiasOrigen,
          dnrpaSeccionales: dnrpaSeccionales,
          planesVencimiento: planesVencimiento,
					equipamientosDisponibles: equipamientosDisponibles,
          tipologias: tipologias,
          funciones: funciones,
          clases: clases,
          proveedoresGps: proveedoresGps,
          divisiones: divisiones
        }, () => {
					$(this.refs.duallistboxequipamientos).bootstrapDualListbox('refresh', true);
				});
      }).catch(function(error) {
  			component.ajaxHandler.handleError(error);
  		});
    }

    window.scrollTo(0, 0);
    $('#dominio').focus();
	}

  getBackendPersonasPorBase (searchTerm) {
    return this.ajaxHandler.getJson('/personas/simple-search?filterNotInternal=true&search=' + searchTerm).then(res => {
      return {
        options: res,
        complete: true
      }
    });
  }

  getPlanPreventivos (descripcion) {
    this.setState({ loading: true });
    let tipo = 'PREVENTIVO'
    let unidadMedidorId = this.state.formData.unidadMedidorId ? this.state.formData.unidadMedidorId : null;
    let url = '/plan-mantenimiento-preventivos/simple-search?'
      + 'descripcion=' + descripcion
      + '&tipo=' + tipo
      + '&unidad_medidor_id=' + unidadMedidorId
      + '&limit=10'
      + '&activo=true'
    if (!this.component)
      this.ajaxHandler.subscribe(this);
    return this.ajaxHandler.getJson(url).then(res => {
      return {
        options: res,
        complete: true
      }
    }).finally(() => {
      this.setState({ loading: false });
    });
  }


  loadFormData() {
    let component = this;
    this.setState({ loading: true });
    this.ajaxHandler.getJson('/moviles/' + this.state.props.match.params.id)
    .then(data => {
      if(data) {
        //this.state.formData.buscar_infracciones = data.buscar_infracciones;
        if(data.kmActual){
          //data.kmActual = data.kmActual;
          component.setState({ kmTemporal: data.kmActual.toLocaleString('de-DE') });
        }
        let estadoClasificadorText = "";
        if(data.estado){
          if(component.state.arrayDisponibles && component.state.arrayDisponibles.includes(data.estado)){
            estadoClasificadorText = component.props.intl.formatMessage({ id: 'Disponible', defaultMessage: 'Disponible' });
          }else if(component.state.arrayNodisponibles && component.state.arrayNodisponibles.includes(data.estado)){
            estadoClasificadorText = component.props.intl.formatMessage({ id: 'No_disponible', defaultMessage: 'No disponible' })
          }
        }
				component.setState({
          direccionForm: {
						latitud: data.guardaLatitud,
						longitud: data.guardaLongitud,
						calle: data.guardaDireccion,
						numero: data.guardaAltura,
						pais: data.guardaPais,
						provincia: data.guardaProvincia,
						localidad: data.guardaLocalidad,
					},
          unidadMedidorIdOriginal: data.unidadMedidorId,
          estadoClasificadorText: estadoClasificadorText
        });
				if(this.state.props.action === 'EDIT'){
          this.ajaxHandler.getJson('/llantas/llantasdemovil/' + data.id)
          .then(llantas => {
            if(llantas){
              component.setState({
                llantasDeMovil: llantas
              })
            }
          });
					data.equipamientos = data.equipamientos.map( equipamiento => equipamiento.id)
        }
        data.tipologia_posicion = data.tipologiaPosicion;
        validateForm(this, "chasis", data);
        
			  component.setState({
          formData: Object.assign(this.state.formData, data),
          labelTitular: ConfigBusiness.get('moviles.titular.label'),
          tipologiaOriginal: data.tipologia_posicion,
          dominioGuardado: data.dominio,
          tarjetaCombustibleOrig: data.ypf_ruta != null ? data.ypf_ruta : ''
        }, () => {
					$(this.refs.duallistboxequipamientos).bootstrapDualListbox('refresh', true);
          if(this.state.props.action === 'EDIT' || this.state.props.action==='VIEW') {
            component.setState({ basellantas: data.base ? {label: data.base.descripcion, value: data.base.id} : null});
            if(data.titular) this.handleFormChangeSelectTitular({ value: data.titular.id, label: data.titular.nombre });
            if(data.marca) this.handleMarcaChange({ value: data.marca.id, label: data.marca.nombre })
            .then(() => {
              if(data.modelo)
                {
                  let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
                  formDataCopy.rendimientoEsperadoModelo = data.modelo.rendimientoEsperado ? data.modelo.rendimientoEsperado : '';
                  formDataCopy.capacidadTanqueModelo = data.modelo.capacidadTanque ? data.modelo.capacidadTanque : '';
                  formDataCopy.modelo = { id: data.modelo.id, label: data.modelo.nombre };
                  this.setState({
                    formData: formDataCopy
                  }, () => { this.getPlanPreventivos()});
                  this.ajaxHandler.getJson('/modelo-tipos/' + data.modelo.tipo.id)
                  .then(data => {
                    if(data){
                      let valores_dinamicos = [];
                      valores_dinamicos = castValoresDinamicos(data.atributos_dinamicos, this.state.formData.valores_dinamicos);
                      component.setState({
                        valores_dinamicos: valores_dinamicos
                      })
                    }
                  });


                }
            });
          }

          if(component.props.action !== 'ADD') {
            if(component.state.formData.adjuntos) {
              let adjuntos = component.state.formData.adjuntos;
              for(let i in adjuntos) {
                let fileUrl = '/moviles/adjunto/' + adjuntos[i].adjunto;
                let responseContentType;
                component.ajaxHandler.fetch(fileUrl, {
                  method: 'GET',
                  headers: {
                    'Authorization-Token': localStorage.getItem("token")
                  }
                }).then(response => {
                  if (response.status === 200) {
                    responseContentType = response.headers.get('Content-Type');
                    return response.blob();
                  }
                }).then(imgBlob => {
                  let imgUrl = URL.createObjectURL(imgBlob);
                  var mockFile = {
                    adjunto: adjuntos[i].adjunto,
                    name: adjuntos[i].archivoNombre
                  };
                  this.adjuntosDropzone.emit("addedfile", mockFile);
                  this.adjuntosDropzone.files.push(mockFile);
                  this.adjuntosDropzone.emit("thumbnail", mockFile, responseContentType.includes('image') ? imgUrl : '/images/file.png');
                  this.adjuntosDropzone.emit("complete", mockFile);
                }).catch(() => {
                });
              }
            }
          }
        });
      }

      // if(this.state.props.action === 'EDIT') validateFormAll(this, this.state.formData)

    }).finally(() => {
      this.setState({ loading: false });
    });
  }


  loadTicketsData() {
    if(Security.hasPermission('TICKETS_MOVIL_LISTAR')) {
      let component = this;
      this.ajaxHandler.getJson('/tickets/gestoriaAbiertos/movil/' + this.state.props.match.params.id)
      .then(data => {
        if(data)
          component.setState({
            tickets: data
          })
      });
    }
  }

  initFileUpload() {
    let component = this;

    this.fileUploadConfig = {
      showFiletypeIcon: this.props.action === 'ADD' ? true : false,
      postUrl: Config.get('apiUrlBase') + '/moviles/adjunto'
    };

    this.fileUploadDjsConfig = {
      addRemoveLinks: false,
      thumbnailMethod: 'crop',
      dictDefaultMessage: component.state.props.action !== 'VIEW' ? "Haga click aquí o <br> arrastre sus archivos a este área." : '',
      uploadMultiple: true,
      parallelUploads: 1,
      headers: {
        "Authorization-Token": localStorage.getItem("token")
      },
      previewTemplate: `
        <div class="dz-preview dz-image-preview">
          <div class="dz-image">
            <img data-dz-thumbnail src="/images/file.png" />
          </div>
          <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress="" style="width: 0%;"></span></div>
          <div class="dz-success-mark">
            <svg width="54px" height="54px" viewBox="0 0 54 54" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns">      <title>Check</title>      <defs></defs>      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">        <path d="M23.5,31.8431458 L17.5852419,25.9283877 C16.0248253,24.3679711 13.4910294,24.366835 11.9289322,25.9289322 C10.3700136,27.4878508 10.3665912,30.0234455 11.9283877,31.5852419 L20.4147581,40.0716123 C20.5133999,40.1702541 20.6159315,40.2626649 20.7218615,40.3488435 C22.2835669,41.8725651 24.794234,41.8626202 26.3461564,40.3106978 L43.3106978,23.3461564 C44.8771021,21.7797521 44.8758057,19.2483887 43.3137085,17.6862915 C41.7547899,16.1273729 39.2176035,16.1255422 37.6538436,17.6893022 L23.5,31.8431458 Z M27,53 C41.3594035,53 53,41.3594035 53,27 C53,12.6405965 41.3594035,1 27,1 C12.6405965,1 1,12.6405965 1,27 C1,41.3594035 12.6405965,53 27,53 Z" id="Oval-2" stroke-opacity="0.198794158" stroke="#747474" fill-opacity="0.816519475" fill="#FFFFFF" sketch:type="MSShapeGroup"></path>      </g>    </svg>
          </div>
          <div class="dz-error-mark">
            <svg width="54px" height="54px" viewBox="0 0 54 54" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns">      <title>Error</title>      <defs></defs>      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">        <g id="Check-+-Oval-2" sketch:type="MSLayerGroup" stroke="#747474" stroke-opacity="0.198794158" fill="#FFFFFF" fill-opacity="0.816519475">          <path d="M32.6568542,29 L38.3106978,23.3461564 C39.8771021,21.7797521 39.8758057,19.2483887 38.3137085,17.6862915 C36.7547899,16.1273729 34.2176035,16.1255422 32.6538436,17.6893022 L27,23.3431458 L21.3461564,17.6893022 C19.7823965,16.1255422 17.2452101,16.1273729 15.6862915,17.6862915 C14.1241943,19.2483887 14.1228979,21.7797521 15.6893022,23.3461564 L21.3431458,29 L15.6893022,34.6538436 C14.1228979,36.2202479 14.1241943,38.7516113 15.6862915,40.3137085 C17.2452101,41.8726271 19.7823965,41.8744578 21.3461564,40.3106978 L27,34.6568542 L32.6538436,40.3106978 C34.2176035,41.8744578 36.7547899,41.8726271 38.3137085,40.3137085 C39.8758057,38.7516113 39.8771021,36.2202479 38.3106978,34.6538436 L32.6568542,29 Z M27,53 C41.3594035,53 53,41.3594035 53,27 C53,12.6405965 41.3594035,1 27,1 C12.6405965,1 1,12.6405965 1,27 C1,41.3594035 12.6405965,53 27,53 Z" id="Oval-2" sketch:type="MSShapeGroup"></path>        </g>      </g>    </svg>
          </div>
          <div class="dz-error-message"><span data-dz-errormessage></span></div>
          <div class="dz-filename" style="width: 120px;"><span data-dz-name title="data-dz-name"></span></div>
          <div class="dz-view"><a href="" target="_blank">VER</a></div>
          ${component.state.props.action !== 'VIEW' ? '<div class="dz-remove"><a href="" data-dz-remove>ELIMINAR</a></div>' : ''}
        </div>
      `,

      init: function () {
        let dropzone = this;

        this.on('success', (file, response) => {
          file.adjunto = response.archivo;
          let formDataCopy = JSON.parse(JSON.stringify(component.state.formData));
          formDataCopy.adjuntos.push({adjunto: response.archivo});
          component.setState({
            formData: formDataCopy
          });
        });

        this.on('removedfile', (file) => {
          let formDataCopy = JSON.parse(JSON.stringify(component.state.formData));
          formDataCopy.adjuntos = formDataCopy.adjuntos.filter(a => a.adjunto !== file.adjunto);
          component.setState({
            formData: formDataCopy
          });

          component.ajaxHandler.fetch('/moviles/adjunto/' + file.adjunto, {
            method: 'DELETE'
          })
          .then(response => {
            if(response.status !== 204) {
              Dialog.alert(component.props.intl ,{
                title: component.props.intl.formatMessage({ id: 'movilesAbm.erros.delete_file.error_eliminar_archivo', defaultMessage: 'Error al eliminar el archivo.' })
              });
            }
          }).catch((error) => {
            component.ajaxHandler.handleError(error);
          });
        });

        this.on('error', (file, errormessage, response) => {
          dropzone.removeFile(file);
          Dialog.alert(component.props.intl ,{
            title: component.props.intl.formatMessage({ id: 'movilesAbm.erros.save_file.error_cargar_archivo', defaultMessage: 'Error al cargar el archivo.' })
          });
        });

        this.on('addedfile', function(file) {
          $(file.previewElement).find('.dz-view > a').on('click', (e) => {
            component.ajaxHandler.fetch('/moviles/adjunto/' + file.adjunto, {
              method: 'GET',
              headers: {
                'Authorization-Token': localStorage.getItem("token")
              }
            }).then(response => {
              if (response.status === 200) {
                return response.blob();
              }
            }).then(fileBlob => {
              let fileUrl = URL.createObjectURL(fileBlob);
              window.open(fileUrl);
            }).catch(() => {
            });

            e.preventDefault();
          });
        });

        if(component.state.props.action === 'VIEW') {
          dropzone.disable();
        }
      }
    };

    this.fileUploadHandlers = {
      init: dz => {
        this.adjuntosDropzone = dz;
      }
    };
  }
	handleChangeDireccion(dataDireccion, valueDireccion, direccionValid){
		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    let mapeo = {
			latitud: 'guardaLatitud',
			longitud: 'guardaLongitud',
			calle: 'guardaDireccion',
			numero: 'guardaAltura',
			pais: 'guardaPais',
			provincia: 'guardaProvincia',
			localidad: 'guardaLocalidad',
		}

	  formDataCopy[mapeo[dataDireccion]] = valueDireccion;
    formDataCopy['direccionValid'] = direccionValid;
    this.setState({
      formData: formDataCopy
    });

	}

  handleMarcaChange(object) {
    return new Promise((resolve, reject) => {
      if(object) {
        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
        formDataCopy.marca = { id: object.value, label: object.label };
        formDataCopy.modelo = null;

        validateForm(this, 'marca', formDataCopy);

        this.setState({
          formData: formDataCopy,
          modelos: []
        }, () => {
          this.setState({ modelosLoading: true });
          this.ajaxHandler.getJson('/modelos/marca/' + object.value + '/select')
          .then((data) => {
            this.setState({
              modelos: data
            }, () => resolve());
          }).finally(() => {
            this.setState({ modelosLoading: false });
          });
        });
      } else {
        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
        formDataCopy.marca = null;
        formDataCopy.modelo = null;

        validateForm(this, 'marca', formDataCopy);

        this.setState({
          formData: formDataCopy,
          provincias: []
        }, () => resolve());
      }
    });
  }
	handleGuardaPersonalizada(guardaEnBase){
		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy['guardaEnBase'] = guardaEnBase;
		this.setState({
				formData: formDataCopy
		});
	}

  handleModeloChange(object) {
    return new Promise((resolve, reject) => {
      if(object) {
        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
        formDataCopy.modelo = { id: object.value, label: object.label};

        validateForm(this, 'modelo', formDataCopy);

        this.setState({
          formData: formDataCopy
        }, () => {
            this.setState({unidadMedidorIdLoading: true, planesPreventivosLoading: true, planesVencimientosLoading: true});
            this.ajaxHandler.getJson('/modelos/' + object.value)
            .then((data) => {
              formDataCopy.tipologia_posicion = data.tipologiaPosicion
              this.setState({ formData: formDataCopy });
              this.ajaxHandler.getJson('/modelo-tipos/' + data['tipo']['id'])
              .then((data) => {
                let valores_dinamicos = castValoresDinamicos(data.atributos_dinamicos, this.state.formData.valores_dinamicos)
                this.setState({
                  unidadMedidorId: data['unidadMedidorId'],
                  planPreventivo: data['planPreventivo'],
                  planVencimiento: data['planVencimiento'],
                  valores_dinamicos: valores_dinamicos
                }, () => {
                  // Fuerzo al default para prevenir asignacion de plan y unidad medidora que no corresponda al tipo de modelo
                  this.state.formData.planPreventivo = this.state.planPreventivo;
                  this.state.formData.unidadMedidorId = this.state.unidadMedidorId;

                  if(!formDataCopy.planVencimiento)
                  {
                    this.state.formData.planVencimiento = this.state.planVencimiento;
                  }
                this.setState({unidadMedidorIdLoading: false, planesPreventivosLoading: false, planesVencimientosLoading: false});
              });
              });
            }).finally(() => resolve());
        });
      } else {
        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
        formDataCopy.modelo = null;

        validateForm(this, 'modelo', formDataCopy);

        this.setState({
          formData: formDataCopy
        }, () => resolve());
      }
    });
  }

  handleKmActualChange(event) {
    let maxKm = ConfigBusiness.get('moviles.editaKm.habilitado') === 'true' ? ConfigBusiness.get('moviles.editaKm.maximo') : 0;
    let kmOriginal = this.state.kmTemporal !== null ? parseInt(this.state.kmTemporal.toString().replace(/\D/g,'')) : 0;
    let inputValue = event.target.value == '' ? 0 : event.target.value;
    if (Math.abs(inputValue - kmOriginal) > maxKm) {
      swal({
        title: this.props.intl.formatMessage({ id: 'movilesAbm.modal_updateKm.confirm_diferencia_de_km_mayor_a', defaultMessage: 'Diferencia de Km mayor a ' }) + maxKm + this.props.intl.formatMessage({ id: 'movilesAbm.modal_updateKm.confirm_km_esta_seguro_que_desea_actualizar?', defaultMessage: ' Km. Esta seguro que desea actualizar?' }),
        text: "",
        icon: "warning",
        showCancelButton: true,
        buttons: {
          confirm: {
            text: this.props.intl.formatMessage({ id: 'movilesAbm.modal_updateKm.confirm_aceptar', defaultMessage: 'Aceptar' }),
            value: true,
            visible: true,
            className: "btn btn-success",
            closeModal: true
          },
          cancel: {
            text: this.props.intl.formatMessage({ id: 'movilesAbm.modal_updateKm.confirm_cancelar', defaultMessage: 'Cancelar' }),
            value: null,
            visible: true,
            className: "btn btn-danger",
            closeModal: true,
          }
        }
      }).then(isConfirm => {
        if (isConfirm) {
          this.setState({ kmActual: inputValue});
        } else {
          $('#kmActual').val(kmOriginal);
        }
        swal.close();
      });
    };
  }

  handleInputFormChange(event) {
		const target = event.target;
		const name = target.name;
		const value = target.type === 'checkbox' ? target.checked : target.value;

		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy[name] = value;

    validateForm(this, name, formDataCopy);

		this.setState({
			formData: formDataCopy
		});
  }

  handleChangeAtributoDinamico(index, key, value) {
    this.state.valores_dinamicos[index][key] = value;
    this.setState({
      valores_dinamicos: this.state.valores_dinamicos
    });
  }

  handleInputSeparadoFormChange(event){

    let val = event.target.value.replace('.','');
    val = val.replace(',','.');
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    formDataCopy[event.target.name] = val;
    this.setState({
    formData: formDataCopy,
    kmTemporal: event.target.value
    });

  }

  handleFormChangeSelectString(name, object) {
    return new Promise((resolve, reject) => {
      let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
      if ((name === "traccion" && object !== null) || name === "unidadMedidorId") {
        formDataCopy[name] = object.id;
        if  (name === "unidadMedidorId") {
          formDataCopy['planPreventivo'] = null;
          if (this.state.unidadMedidorIdOriginal && (this.state.unidadMedidorIdOriginal !== object.id)) {
            swal2.fire({
              title: "Tipo de Medidor Diferente!",
              text: "Se actualizara el tipo de medidor y reiniciaran los datos historicos del movil y de las llantas asociadas.",
              icon: "warning",
              confirmButtonText: "Continuar",
            })
          }
        }
        validateForm(this, name, formDataCopy);
        this.setState({
          formData: formDataCopy
        }, () => resolve());
      } else if (name === "estado") {
          this.setEstadoActivo(object ? object.value : null,formDataCopy);
          let estadoClasificadorText = "";
          if(object && object.value){
            if(this.state.arrayDisponibles && this.state.arrayDisponibles.includes(object.value)){
              estadoClasificadorText = this.props.intl.formatMessage({ id: 'Disponible', defaultMessage: 'Disponible' });
            }else if(this.state.arrayNodisponibles && this.state.arrayNodisponibles.includes(object.value)){
              estadoClasificadorText = this.props.intl.formatMessage({ id: 'No_disponible', defaultMessage: 'No disponible' })
            }
          }
          this.setState({
            estadoClasificadorText: estadoClasificadorText
          });
      } else {
          formDataCopy[name] = object === null ? '' : object.value
          validateForm(this, name, formDataCopy);

          this.setState({
            formData: formDataCopy
          }, () => resolve());
      }

    });
  }

  handleFormChangeSelectTitular(titular) {
    return new Promise((resolve, reject) => {
      let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
      formDataCopy['titular'] = titular ? {id: titular.value, nombre: titular.label} : null;
      let titularesCopy = JSON.parse(JSON.stringify(this.state.titulares));
      if (titularesCopy.length < 1) {
        titularesCopy = [titular];
      }

      validateForm(this, 'titular', formDataCopy);

      this.setState({
        formData: formDataCopy,
        titulares: titularesCopy
      }, () => resolve());
    });
  }

  handleFormChangeSelectObject(name, object) {
    if (name === 'tipologia_posicion') {
      return new Promise((resolve, reject) => {
        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
        formDataCopy[name] = object ? object : null;

        validateForm(this, name, formDataCopy);

        this.setState({
          formData: formDataCopy
        }, () => resolve());
      });
    } else if (name === 'claseEloquent') {
      let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
      formDataCopy[name] = object ? object : null;

      validateForm(this, name, formDataCopy);

      this.setState({formData: formDataCopy})
    } else if (name === 'basellantas') {
      this.setState({basellantas: object ? object : null})
    } else {
      return new Promise((resolve, reject) => {
        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
        formDataCopy[name] = object ? { id: object.value } : null;
        validateForm(this, name, formDataCopy);

        this.setState({
          formData: formDataCopy
        }, () => resolve());
      });
    }
  }
  
  handleFormChangeTipologia(name, object){
    if(this.state.props.action === 'EDIT' 
      && this.state.tipologiaOriginal 
      && object 
      && this.state.tipologiaOriginal.id !== object.id
      && this.state.llantasDeMovil.length > 0){
      this.setState({
        showModalTipologiaLlanta: true,
        tipologiaSelect: object,
      });

    } else {
      return new Promise((resolve, reject) => {
        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
        formDataCopy[name] = object ? object : null;

        validateForm(this, name, formDataCopy);

        this.setState({
          formData: formDataCopy
        }, () => resolve());
      });
    }
  }

  closeModalTipologiaLlanta(){
    this.setState({showModalTipologiaLlanta: false});
  }

  updateTipologiaLlanta(){
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    formDataCopy['tipologia_posicion'] = this.state.tipologiaSelect;
    this.setState({
      showModalTipologiaLlanta: false,
      formData: formDataCopy,
      tipologiaSelect: null
    });
  }

  handleChangeCentroCosto(name, object){
      const MySwal = withReactContent(Swal);
      if(!this.state.formData.centroCostos || object.value != this.state.formData.centroCostos.id){
        MySwal.fire({
          title: 'Cambio de Centro de Costo',
          html: <CambioCentroCostoMovil
            id={object.value}
            CECOs = {this.state.centrosCostos}
            dominio = {this.state.formData.dominio}
            success={(message) => {
              MySwal.clickConfirm()
              return new Promise((resolve, reject) => {
                let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
                formDataCopy[name] = object ? { id: object.value } : null;

                validateForm(this, 'centroCostos', formDataCopy);

                this.setState({
                  formData: formDataCopy
                }, () => resolve());
              });
            }}
            failure={(message) => {
              Swal.fire(message)
            }}
            dismiss={() => MySwal.clickCancel()}
          />,
          showConfirmButton: false
        })
      }
  }

  handleFormChangeSelectFuncion(funcion) {
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    formDataCopy['funcion'] = funcion;
    let funcionesCopy = JSON.parse(JSON.stringify(this.state.funciones));
    if (funcionesCopy.length < 1) {
      funcionesCopy = [funcion];
    }
    this.setState({
      formData: formDataCopy,
      funciones: funcionesCopy
    });
  }

  handleChange(name, object) {
    return new Promise((resolve, reject) => {
      let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
      formDataCopy[name] = object;
      validateForm(this, name, formDataCopy);
      this.setState({
        formData: formDataCopy
      }, () => resolve());
    });
  }

  handleDatePickerFormChange(name, event) {
    let date = event ? event.format('YYYY-MM-DD'): null;
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy[name] = date;

    validateForm(this, name, formDataCopy);

		this.setState({
			formData: formDataCopy
		});
  }

  getJefeBase() {
    if(this.state.formData.base) {
      this.ajaxHandler.getJson('/bases/' + this.state.formData.base.id)
      .then((data) => {
        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
        formDataCopy.base.jefe = data.jefe;
        formDataCopy.base.responsable = data.responsable;
        this.setState({
          formData: formDataCopy
        });
      });
    }
  }

  handleAgregarTitular(){
    let ajaxHandler = this.ajaxHandler;
    swal2.fire({
      title: this.props.intl.formatMessage({ id: 'movilesAbm.modal_add_holder.ingresa_el_nombre_del_titular', defaultMessage: 'Ingresa el nombre del titular' }),
      input: 'text',
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return this.props.intl.formatMessage({ id: 'movilesAbm.modal_add_holder.errors_no_puede_estar_vacio_el_nombre', defaultMessage: 'No puede estar vacío el nombre' });
        }
        for (let i = 0; i < this.state.titulares.length; i++) {
          if (this.state.titulares[i].label.toLowerCase() == value.toLowerCase()) {
            return this.props.intl.formatMessage({ id: 'movilesAbm.modal_add_holder.errors_el_titular_ya_existe', defaultMessage: 'El titular ya existe' });
          }
        }
      }
    }).then((result) => {
      if (result.value) {
        ajaxHandler.fetch('/movil-titulares', {
          method: 'POST',
          body: JSON.stringify({
            nombre: result.value
          }),
        }).then(response => response.json()
        .then(nuevoTitular => {
          let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
          formDataCopy['titular'] = nuevoTitular ? nuevoTitular.id : null;
          let titularesCopy = JSON.parse(JSON.stringify(this.state.titulares));
          if (nuevoTitular) {
            titularesCopy.push({ value: nuevoTitular.id, label: nuevoTitular.nombre });
          }
          this.setState({
            formData: formDataCopy,
            titulares: titularesCopy,
          });
        })).catch((error) => {
          let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
          formDataCopy['titular'] = null;
          this.setState({
            formData: formDataCopy
          });
        })
      }
    })
  }

  handleAgregarFuncion(){
    let ajaxHandler = this.ajaxHandler;
    swal2.fire({
      title: this.props.intl.formatMessage({ id: 'movilesAbm.modal_add_funcion.nombre', defaultMessage: 'Ingresa el nombre de la funcion' }),
      input: 'text',
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return this.props.intl.formatMessage({ id: 'movilesAbm.modal_add_funcion.errors_no_puede_estar_vacio_el_nombre', defaultMessage: 'No puede estar vacío el nombre' });
        }
        for (let i = 0; i < this.state.funciones.length; i++) {
          if (this.state.funciones[i].nombre.toLowerCase() == value.toLowerCase()) {
            return this.props.intl.formatMessage({ id: 'movilesAbm.modal_add_funcion.errors_la_funcion_ya_existe', defaultMessage: 'La funcion ya existe' });
          }
        }
      }
    }).then((result) => {
      if (result.value) {
        ajaxHandler.fetch('/funciones', {
          method: 'POST',
          body: JSON.stringify({
            nombre: result.value
          }),
        }).then(response => response.json()
        .then(nuevaFuncion => {
          let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
          formDataCopy['funcion'] = nuevaFuncion ? nuevaFuncion : null;
          let funcionesCopy = JSON.parse(JSON.stringify(this.state.funciones));
          if (nuevaFuncion) {
            funcionesCopy.push({ id: nuevaFuncion.id, nombre: nuevaFuncion.nombre });
          }
          this.setState({
            formData: formDataCopy,
            funciones: funcionesCopy,
          });
        })).catch((error) => {
          let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
          formDataCopy['funcion'] = null;
          this.setState({
            formData: formDataCopy
          });
        })
      }
    })
  }

  handlePlanPreventivoChange(planPreventivo){
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    formDataCopy["planPreventivo"] = planPreventivo;

    validateForm(this, 'planPreventivo', formDataCopy);

    this.setState({
      formData: formDataCopy,
    });
  }

  setEstadoActivo(estado,formDataCopy) {
      let activo = this.state.estadosActivos.findIndex(e => e === estado) !== -1 ? true : false;
      formDataCopy['activo'] = activo;
      formDataCopy['estado'] = estado;

      if (!activo && this.state.tickets.length && Security.hasPermission('TICKETS_CANCELAR_GESTORIA')) {
          swal({
            title: "Este móvil tiene " + this.state.tickets.length + " tickets de gestoría asociados. ¿Desea cancelarlos?",
            text: "",
            icon: "warning",
            buttons: {
              confirm: {
                text: "Cancelar Tickets",
                value: true,
                visible: true,
                className: "btn btn-danger",
                closeModal: true
              },
              cancel: {
                text: "Continuar, sin cancelaciones",
                value: null,
                visible: true,
                className: "btn",
                closeModal: true,
              }
            }
          }).then(isConfirm => {
            if(isConfirm) {
              this.state.tickets.forEach((ticket, i) => {
                this.ajaxHandler.fetch('/tickets/cancelar/' + ticket.id, {
                  method: 'POST',
                  body: JSON.stringify({
                    comentario: 'Inhabilito Móvil'
                  }),
                })
                .then(() => {
                  this.setState({
                    formData: formDataCopy
                  });
                })

              });
            }
            else {
              this.setState({
                formData: formDataCopy
              });
            }

          })
      } else if(!activo && this.state.tickets.length) {
        swal({
            title: "Este móvil tiene " + this.state.tickets.length + " tickets de gestoría asociados, cancele el ticket.",
            text: "",
            icon: "warning",
            buttons: {
              confirm: {
                text: "Aceptar",
                value: true,
                visible: true,
                className: "btn btn-success",
                closeModal: true
              }
            }
          })
      } else {
        this.setState({
          formData: formDataCopy
        });
      }
  }

  validateDomainChange(){
    if(this.state.props.action === 'EDIT' && this.state.dominioGuardado !== this.state.formData.dominio && 
      !this.state.validationErrors.dominio){
        let MySwal = withReactContent(Swal);
        let dominioBack = this.state.dominioGuardado;
        let dominio = this.state.formData.dominio
        MySwal.fire({
          title: this.props.intl.formatMessage({ id: 'Cambio_de_vehículo', defaultMessage: 'Cambio de vehículo' }),
          html: <CambioDominioMovil
            id={this.state.formData.id}
            CECOs = {this.state.centrosCostos}
            dominio = {this.state.formData.dominio}
            intl={this.props.intl}
            success={(message) => {
              MySwal.clickConfirm()
              return new Promise((resolve, reject) => {
                let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
                validateForm(this, 'dominio', formDataCopy);
                dominioBack = dominio;
                this.setState({
                  formData: formDataCopy,
                  dominioGuardado: dominio
                }, () => {
                  Swal.fire(message);
                  resolve()
                });
              });
            }}
            failure={(message) => {
              Swal.fire(message)
            }}
            dismiss={() => {
              MySwal.clickCancel()
            }}
          />,
          showConfirmButton: false,
          onAfterClose: () =>  {
            if(dominioBack !== dominio){
              let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
              formDataCopy.dominio = dominioBack;
              this.setState({formData: formDataCopy});
            }
          }
        })
    }
  }

	handleSubmit(event) {
    if(this.state.formData.dominio === this.state.dominioGuardado || this.props.action === 'ADD'){
      this.setState({ loading: true });
		  let component = this;
      this.state.formData.valores_dinamicos = this.state.valores_dinamicos;
      this.ajaxHandler.fetch('/moviles' + (this.props.action === 'ADD' ? '' : '/' + this.state.formData.id), {
        method: this.props.action === 'ADD' ? 'POST' : 'PUT',
        body: JSON.stringify({
          ...this.state.formData
        }),
      }).then(response => {
        if(response.status !== 400) {
          component.exit();
        } else {
          response.json()
          .then(data => {
            if(data.detalle.indexOf("movilController.errors.no_puede_cambiar_tipologia_posicion_tiene_llantas") >= 0){
                swal({
                    title: this.props.intl.formatMessage({ id: 'movilesAbm.modal_error_cambio_tipologia_posicion.titulo', defaultMessage: 'Vehículo tiene llantas asignadas' }),
                    text: this.props.intl.formatMessage({ id: 'movilesAbm.modal_error_cambio_tipologia_posicion.texto', defaultMessage: 'Este vehículo cuenta con llantas asignadas, debe desasignarlas para poder cambiar el diagrama de Posicion.' }) ,
                    icon: "error",
                    button: this.props.intl.formatMessage({ id: 'movilesAbm.modal_error_cambio_tipologia_posicion.button_continuar', defaultMessage: 'Continuar' }),
                  })
            }
            else{
                this.setState({
                  errors: data.detalle
                });
            }
          });
        }
        window.scrollTo(0,0);
      }).catch((error) => {
        this.ajaxHandler.handleError(error);
      }).finally(() => {
        this.setState({ loading: false });
      });
    }else{
      this.validateDomainChange();
    }
		event.preventDefault();
	}

	handleCancel(event) {
		this.exit();
	}

  handleBuscarInfracciones(buscar_infracciones){
		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
		formDataCopy['buscar_infracciones'] = buscar_infracciones;
		this.setState({
				formData: formDataCopy
		});
	}

  handleChangePropio(name, value){
		let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    if(name == 'rendimientoPropio'){
      formDataCopy['rendimientoPropio'] = value;
      formDataCopy['rendimientoEsperado'] = value ? '' : this.state.formData.rendimientoEsperadoModelo;
    }
    if(name == 'tanquePropio'){
      formDataCopy['tanquePropio'] = value;
      formDataCopy['capacidadTanque'] = value ? '' : this.state.formData.capacidadTanqueModelo;
    }

    validateForm(this, name, formDataCopy);

		this.setState({
				formData: formDataCopy
		});
	}

	exit() {
		this.setState({
			redirectTo: '/moviles'
    });
	}

    handleGpsChange(gps, force, sync_avl) {
        let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));

        formDataCopy['gps_model'] = gps
        formDataCopy['gps_model_id'] = (gps) ? gps.id : null
        formDataCopy['force_avl_odometer'] = force
        formDataCopy['sync_avl'] = sync_avl

        this.setState({
            formData: formDataCopy
        })
    }

  handleSetTipo(tipo) {
      this.setState({
          tipoModal: tipo
      })
  }



  handleAdjuntosChange(adjuntosCategorias) {
    let arrayAdjuntos = []
    adjuntosCategorias.map((adjuntoCategoria)=>{
      adjuntoCategoria.adjuntos.map((adjunto)=>{
        adjunto.categoria = {
          id: adjuntoCategoria.id,
          nombre:adjuntoCategoria.nombre
        }
        arrayAdjuntos.push(adjunto)
      })
    })
    let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
    formDataCopy.adjuntos = arrayAdjuntos;
    this.setState({
      formData: formDataCopy
    });
  }

handleChangeTarjetaCombustible(){
  if(this.state.props.action === 'EDIT' ){
    let MySwal = withReactContent(Swal);
    const tjtComOrig = this.state.tarjetaCombustibleOrig.split(/[,;]/);
    const tjtCom = this.state.formData.ypfRuta.split(/[,;]/);

    // si no cambio el contenido no hago nada
    if (tjtComOrig.length === tjtCom.length && tjtComOrig.every((e, i) => e === tjtCom[i])) return;
    
    const eliminados = tjtComOrig.filter(item =>!tjtCom.includes(item) && item !== '');
    //si solo hay datos nuevos no se registra historial
    if (eliminados.length > 0) {
      MySwal.fire({
        title: "Cambio de " + this.props.intl.formatMessage({
          id: "movilesAbm.render.administracion.label_YPF_ruta", defaultMessage: "YPF Ruta"
        }),
        html: <CambioTarjetaCombustible
          id={this.state.formData.id}
          tarjetaCombustible = { tjtCom.join() }
          dominio = {this.state.formData.dominio}
          intl={this.props.intl}
          success={(message) => {
            MySwal.clickConfirm()
            return new Promise((resolve, reject) => {
              let formDataCopy = JSON.parse(JSON.stringify(this.state.formData));
              validateForm(this, 'ypfRuta', formDataCopy);
              this.setState({
                formData: formDataCopy,
              }, () => {
                Swal.fire({
                  title: "Se ha actualizado exitosamente la " + this.props.intl.formatMessage({
                    id: "movilesAbm.render.administracion.label_YPF_ruta", defaultMessage: "YPF Ruta"
                  })
                });
                this.setState({
                  tarjetaCombustibleOrig: this.state.formData.ypfRuta
                }); 
                resolve()
              });
            });
          }}
          failure={(message) => {
            Swal.fire(message)
          }}
          dismiss={() => {
            MySwal.clickCancel()
          }}
        />,
        showConfirmButton: false,
        onAfterClose: () =>  {
          let formDataCopy = this.state.formData;
          formDataCopy.ypfRuta = this.state.tarjetaCombustibleOrig
          this.setState({
            formData: formDataCopy
          });
        }
      })
    } else {
      const data = {
        id: this.state.formData.id,
        dominio: this.state.formData.dominio,
        tarjeta_combustible: this.state.formData.ypfRuta,
        esActualizacion: false
      }
      this.ajaxHandler.fetch("/moviles/actualizar/tarjeta_combustible",
        {
          method: 'POST',
          body: JSON.stringify(data)
        }
      )
      .then(() => {
        Swal.fire({
          title: "Se ha actualizado exitosamente la " + this.props.intl.formatMessage({
            id: "movilesAbm.render.administracion.label_YPF_ruta", defaultMessage: "YPF Ruta"
          })
        });
        this.setState({
          tarjetaCombustibleOrig: this.state.formData.ypfRuta
        });
      })
      .catch((error) => {
        let formDataCopy = this.state.formData;
        formDataCopy.ypfRuta = this.state.tarjetaCombustibleOrig
        this.setState({
          formData: formDataCopy
        });
      });
    }

  }
}



	render() {
    // this.formValidation.validate();
		let state = this.state;
    let formData = state.formData;
    let validationState = state.schema ? state.schema.isValidSync(formData) : null;

    let base = formData.base ? state.bases.find(e => e.value === formData.base.id) : null;
    let marca = formData.marca ? state.marcas.find(e => e.value === formData.marca.id) : null;
    let modelo = formData.modelo ? state.modelos.find(e => e.value === formData.modelo.id) : null;
  
    // let planPreventivo = formData.planPreventivo ? state.planesPreventivo.find(e => e.value === formData.planPreventivo.id) : null;
    let planVencimiento = formData.planVencimiento ? state.planesVencimiento.find(e => e.value === formData.planVencimiento.id) : null;
    let centroCostos = state.viewCentroCostos && formData.centroCostos ? state.centrosCostos.find(e => e.value === formData.centroCostos.id) : null;
    let cebe = state.viewCebe && formData.cebe ? state.cebes.find(e => e.value === formData.cebe.id) : null;
    let tipologia = formData.tipologia_posicion_id && !formData.tipologia_posicion ? state.tipologias.find(e => e.id === formData.tipologia_posicion_id) : null;
    let estado = formData.estado !== '' ? state.estados.find(e => e.value === formData.estado) : null;
    let companiaOrigen = formData.companiaOrigen !== '' ? state.companiasOrigen.find(e => e.value === formData.companiaOrigen) : null;
    let titular = formData.titular ? state.titulares.find(e => e.value === formData.titular || e.value == formData.titular["id"]) : null;
    let movilCombustibleTipo = formData.movilCombustibleTipo ? state.movilCombustibleTipos.find(e => e.value === formData.movilCombustibleTipo.id) : null;
    let dnrpaSeccional = formData.dnrpaSeccional ? state.dnrpaSeccionales.find(e => e.value === formData.dnrpaSeccional.id) : null;
    let proveedorGps = formData.proveedorGps ? state.proveedoresGps.find(e => e.value === formData.proveedorGps.id) : null;
    let division = formData.division ? state.divisiones.find(e => e.value === formData.division.id) : null;
    let requiredSymbol = this.state.props.action !== 'VIEW' ? ' *' : '';

		return (
			<React.Fragment>
        {this.state.redirectTo && <Redirect push to={this.state.redirectTo} />}
				{this.state.loading && <Loading />}
			  <div className="row">
          <div className="col-md-12">
            <div className="alert alert-danger" role="alert" hidden={this.state.errors.length===0}>
							{this.state.errors.map((e, i) => <li key={i}><FormattedMessage {...(backendStrings[e] ? backendStrings[e] : backendStrings['errors.default'])}/></li>)}
						</div>
            <form className="form form-horizontal" ref="form" onSubmit={this.handleSubmit}>
              <div className="form-body">
                <div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body">
                      <h4 className="form-section">
                        <i className="la la-info-circle"></i> <FormattedMessage id="movilesAbm.render.general_data.header_datos_generales" defaultMessage="Datos Generales"/> <div className="float-right" style={{fontSize:'14px'}}>* <FormattedMessage id="movilesAbm.render.general_data.campos requeridos" defaultMessage="campos requeridos"/></div>
                      </h4>
                      <div className="row">
                        {/* DOMINIO */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="dominio">
                              <FormattedMessage id="movilesAbm.render.general_data.label_dominio" defaultMessage="Dominio"/>{requiredSymbol}:
                            </label>
                            <div className="col-md-9">
                              {(this.state.disableAvlFields && this.props.action === 'EDIT' )|| this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('MOVILES_MODIFICAR_DATOS_GENERALES')) ? (
                              <div className="form-control-static col-form-label form-value">{formData.dominio ? formData.dominio : ''}</div>
                              ) : (
                              <div>
                                <input
                                  type="text"
                                  id="dominio"
                                  name="dominio"
                                  placeholder={"("+this.props.intl.formatMessage({ id: 'movilesAbm.render.general_data.placeholder_dominio', defaultMessage: 'Requerido' })+")"} value={formData.dominio ? formData.dominio : ''}
                                  onChange={this.handleInputFormChange}
                                  className={this.state.validationErrors.dominio ? 'form-control is-invalid' : 'form-control'}
                                  onBlur={this.validateDomainChange}
                                />
                                <small className="text-danger">{this.state.validationErrors.dominio}</small>
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* ESTADO */}
                        { ConfigBusiness.get('moviles.estado.habilitado') === 'true' ? (
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="estado">
                              <FormattedMessage id="movilesAbm.render.general_data.label_estado" defaultMessage="Estado"/>{requiredSymbol}:
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('MOVILES_MODIFICAR_DATOS_GENERALES')) ? (
                              <div className="form-control-static col-form-label form-value">{this.state.formData.estado ? this.state.formData.estado : ''}</div>
                              ) : (
                              <div>
                                <Select
                                  id="estado"
                                  name="estado"
                                  placeholder={!state.estadosLoading ? this.props.intl.formatMessage({ id: 'movilesAbm.render.general_data.placeholder_estado', defaultMessage: 'Seleccione' }) : ''}
                                  options={state.estados}
                                  valueKey='value'
                                  labelKey='label'
                                  value={estado ? { value: estado.id, label: estado.label } : null}
                                  onChange={(e) => this.handleFormChangeSelectString("estado", e)}
                                  isLoading={state.estadosLoading}
                                  disabled={state.estadosLoading}
                                  className={this.state.validationErrors.estado ? 'is-invalid' : ''}
                                />
                                <small className="text-danger">{this.state.validationErrors.estado}</small>
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                        ):null}
                      </div>
                      <div className="row">
                        {/* BASE */}
                        { ConfigBusiness.get('moviles.base.habilitado') === 'true' && ConfigBusiness.get('moviles.base.mostrarEnDatosGenerales') === 'true'? (
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="base">
                              <FormattedMessage id="movilesAbm.render.general_data.label_base" defaultMessage="Base"/>{requiredSymbol}:
                            </label>
                            <div className="col-md-9">
                              {(this.state.disableAvlFields && this.props.action === 'EDIT' ) || this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('MOVILES_MODIFICAR_DATOS_GENERALES')) ? (
                              <div className="form-control-static col-form-label form-value">{formData.base && formData.base.descripcion ? formData.base.descripcion : ''}</div>
                              ) : (
                              <div>
                                <Select
                                  id="base"
                                  name="base"
                                  placeholder={!state.basesLoading ? this.props.intl.formatMessage({ id: 'movilesAbm.render.general_data.placeholder_base', defaultMessage: 'Seleccione' }) : ''}
                                  options={this.state.bases}
                                  valueKey='value'
                                  labelKey='label'
                                  value={base ? { value: base.id, label: base.label } : null}
                                  onChange={(e) => this.handleFormChangeSelectObject("base", e).then(() => { this.getJefeBase() })}
                                  isLoading={state.basesLoading}
                                  disabled={state.basesLoading}
                                  className={this.state.validationErrors.base ? 'is-invalid' : ''}
                                />
                                <small className="text-danger">{this.state.validationErrors.base}</small>
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                        ):null}
                        { ConfigBusiness.get('moviles.clasificador.habilitado') === "true" ? 
                          <div className="col-md-6">
                            <div className="form-group row" style={{display: formData.estado ? '' : 'none' }}>
                              <label className="col-md-3 label-control col-form-label" htmlFor="Disponibilidad">
                                <FormattedMessage id="Disponibilidad" defaultMessage="Disponibilidad"/> :
                              </label>
                              <div className="col-md-9">
                                <div className="form-control-static col-form-label form-value">{state.estadoClasificadorText}</div>
                              </div>
                            </div>
                          </div>

                        : null}
                      </div>
                      <div className="row">
                        {/* Responsable Base */}
                        <div className="col-md-6">
                          <div className="form-group row" style={{display: formData.base ? '' : 'none' }}>
                            <label className="col-md-3 label-control col-form-label" htmlFor="responsableBase">
                              <FormattedMessage id="movilesAbm.render.general_data.responsable_base" defaultMessage="Responsable Base"/> :
                            </label>
                            <div className="col-md-9">
                              <div className="form-control-static col-form-label form-value">{formData.base ? (formData.base.responsable ? formData.base.responsable.nombre + ' ' + formData.base.responsable.apellido : '') : ''}</div>
                            </div>
                          </div>
                        </div>
                        {/* Jefe BASE */}
                        <div className="col-md-6">
                          <div className="form-group row" style={{display: formData.base ? '' : 'none' }}>
                            <label className="col-md-3 label-control col-form-label" htmlFor="jefeBase">
                              <FormattedMessage id="movilesAbm.render.general_data.label_jefe_de_base" defaultMessage="Jefe de Base"/> :
                            </label>
                            <div className="col-md-9">
                              <div className="form-control-static col-form-label form-value">{formData.base ? (formData.base.jefe ? formData.base.jefe.nombre + ' ' + formData.base.jefe.apellido : '') : ''}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {ConfigBusiness.get('moviles.estadosErp.habilitado') === 'true' ?
                        <div className="row">
                          {/* Estado SAP */}
                          <div className="col-md-6">
                            <div className="form-group row">
                              <label className="col-md-3 label-control col-form-label" htmlFor="EstadoErp">
                                <FormattedMessage id="movilesAbm.render.general_data.estado_erp" defaultMessage="Estado SAP"/> :
                              </label>
                              <div className="col-md-9">
                                <div className="form-control-static col-form-label form-value">{formData.estadoErp ? formData.estadoErp : ''}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      : null}
                      <div className="row">
                        {/* Unidad */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="unidad">
                              <FormattedMessage id="movilesAbm.render.general_data.label_unidad" defaultMessage="Unidad"/> :
                            </label>
                            <div className="col-md-9">
                              {(this.state.disableAvlFields && this.props.action === 'EDIT' ) || this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('MOVILES_MODIFICAR_DATOS_GENERALES')) ? (
                              <div className="form-control-static col-form-label form-value">{formData.unidad ? formData.unidad : ''}</div>
                              ) : (
                              <div>
                                <input type="text" className="form-control" id="unidad" name="unidad" placeholder="" value={formData.unidad ? formData.unidad : ''} onChange={this.handleInputFormChange} />
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* Gerenciador */}
                        <div className="col-md-6">
                          <div className="form-group row" style={{display: formData.base && formData.base.gerenciador ? '' : 'none' }}>
                            <label className="col-md-3 label-control col-form-label" htmlFor="gerenciador">
                              <FormattedMessage id="movilesAbm.render.general_data.label_gerenciador" defaultMessage="Gerenciador"/> :
                            </label>
                            <div className="col-md-9">
                              <div className="form-control-static col-form-label form-value">{formData.base && formData.base.gerenciador ? formData.base.gerenciador.razonSocial : ''}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {/* MARCA */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label text-nowrap" htmlFor="marca">
                              <FormattedMessage id="movilesAbm.render.general_data.label_marca" defaultMessage="Marca"/>{ConfigBusiness.get('moviles.marca.obligatorio') === 'true' ? requiredSymbol : ' ' }:
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('MOVILES_MODIFICAR_DATOS_GENERALES')) ? (
                              <div className="form-control-static col-form-label form-value">{formData.marca && formData.marca.label ? formData.marca.label : ''}</div>
                              ) : (
                              <div>
                                <Select
                                  id="marca"
                                  name="marca"
                                  placeholder={!state.marcasLoading ? this.props.intl.formatMessage({ id: 'movilesAbm.render.general_data.placeholder_marca', defaultMessage: 'Seleccione' }) : ''}
                                  options={this.state.marcas}
                                  valueKey='value'
                                  labelKey='label'
                                  value={marca ? { value: marca.id, label: marca.label } : null}
                                  onChange={(e) => this.handleMarcaChange(e)}
                                  isLoading={state.marcasLoading}
                                  disabled={this.state.modelosLoading || state.selectDisable}
                                  className={this.state.validationErrors.marca ? 'is-invalid' : ''}
                                />
                                <small className="text-danger">{this.state.validationErrors.marca}</small>
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* MODELO */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label text-nowrap" htmlFor="modelo">
                              <FormattedMessage id="movilesAbm.render.general_data.label_modelo" defaultMessage="Modelo"/>{ConfigBusiness.get('moviles.modelo.obligatorio') === 'true' ? requiredSymbol : ' ' }:
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('MOVILES_MODIFICAR_DATOS_GENERALES')) ? (
                              <div className="form-control-static col-form-label form-value">{formData.modelo && formData.modelo.label ? formData.modelo.label : ''}</div>
                              ) : (
                              <div>
                                <Select
                                  id="modelo"
                                  name="modelo"
                                  placeholder={formData.marca && !state.modelosLoading ? this.props.intl.formatMessage({ id: 'movilesAbm.render.general_data.placeholder_modelo', defaultMessage: 'Seleccione' }) : ''}
                                  options={this.state.modelos}
                                  valueKey='value'
                                  labelKey='label'
                                  value={modelo ? { value: modelo.id, label: modelo.label } : null}
                                  onChange={(e) => this.handleModeloChange(e)}
                                  /*disabled={!formData.marca || this.state.modelosLoading}*/
                                  disabled={!formData.marca || this.state.modelosLoading || state.selectDisable}
                                  isLoading={state.modelosLoading}
                                  className={this.state.validationErrors.modelo ? 'is-invalid' : ''}
                                  />
                                  <small className="text-danger">{this.state.validationErrors.modelo}</small>
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {/* CHASIS */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="chasis">
                              <FormattedMessage id="movilesAbm.render.general_data.label_chasis" defaultMessage="Chasis"/>{ConfigBusiness.get('moviles.chasis.obligatorio') === 'true' ? requiredSymbol : ' ' }:
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('MOVILES_MODIFICAR_DATOS_GENERALES')) ? (
                              <div className="form-control-static col-form-label form-value">{formData.chasis ? formData.chasis : ''}</div>
                              ) : (
                              <div>
                                <input 
                                  type="text"
                                  id="chasis"
                                  name="chasis"
                                  placeholder=""
                                  onChange={this.handleInputFormChange}
                                  className={this.state.validationErrors.chasis ? 'form-control is-invalid' : 'form-control'}
                                  value={formData.chasis ? formData.chasis : ''} 
                                />
                                <small className="text-danger">{this.state.validationErrors.chasis}</small>
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* MOTOR */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="motor">
                              <FormattedMessage id="movilesAbm.render.general_data.label_motor" defaultMessage="Motor"/>{ConfigBusiness.get('moviles.motor.obligatorio') === 'true' ? requiredSymbol : ' ' }:
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('MOVILES_MODIFICAR_DATOS_GENERALES')) ? (
                              <div className="form-control-static col-form-label form-value">{formData.motor ? formData.motor : ''}</div>
                              ) : (
                              <div>
                                <input type="text" className={this.state.validationErrors.motor ? 'form-control is-invalid' : 'form-control'} id="motor" name="motor" placeholder="" value={formData.motor ? formData.motor : ''} onChange={this.handleInputFormChange} />
                                <small className="text-danger">{this.state.validationErrors.motor}</small>
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {/* COLOR */}
                        {this.state.viewColor ? (
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="color">
                              <FormattedMessage id="movilesAbm.render.general_data.label_color" defaultMessage="Color"/> :
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('MOVILES_MODIFICAR_DATOS_GENERALES')) ? (
                              <div className="form-control-static col-form-label form-value">{formData.color ? formData.color : ''}</div>
                              ) : (
                              <div>
                                <input type="text" className="form-control" id="color" name="color" placeholder="" value={formData.color ? formData.color : ''} onChange={this.handleInputFormChange} />
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                        ) : null}
                        {/* AÑO */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="anio">
                              <FormattedMessage id="movilesAbm.render.general_data.label_anio" defaultMessage="Año"/>{ConfigBusiness.get('moviles.anio.obligatorio') === 'true' ? requiredSymbol : ' ' }:
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('MOVILES_MODIFICAR_DATOS_GENERALES')) ? (
                              <div className="form-control-static col-form-label form-value">{formData.anio === 0 ? null : formData.anio}</div>
                              ) : (
                              <div>
                                <input type="text" className={this.state.validationErrors.anio ? 'form-control is-invalid' : 'form-control'} id="anio" name="anio" placeholder="" value={formData.anio === 0 ? '' : formData.anio} onChange={this.handleInputFormChange} />
                                <small className="text-danger">{this.state.validationErrors.anio}</small>
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {/* UNIDAD MEDIDOR */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="unidadMedidor">
                              <FormattedMessage id="movilesAbm.render.general_data.label_unidad_medidor" defaultMessage="Unidad medidor "/> *:
                            </label>
                            <div className="col-md-9">
                              {(this.state.disableAvlFields && this.props.action === 'EDIT' ) || this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('MOVILES_MODIFICAR_DATOS_GENERALES')) ? (
                              <div className="form-control-static col-form-label form-value">{formData.unidadMedidorId ? UNIDAD_MEDIDOR[formData.unidadMedidorId]['label'] : ''}</div>
                              ) : (
                              <div>
                                <SelectWithIcons
                                  placeholder="Tipo"
                                  valueKey="id"
                                  labelKey="label"
                                  disabled={this.state.props.action === 'VIEW'}
                                  clearable={false}
                                  onChange={(e) => this.handleFormChangeSelectString('unidadMedidorId', e)}
                                  value={formData.unidadMedidorId}
                                  options={Object.values(UNIDAD_MEDIDOR)}
                                  isLoading={state.unidadMedidorIdLoading}
                                />
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* TRACCION */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="traccion">
                              <FormattedMessage id="movilesAbm.render.general_data.label_traccion" defaultMessage="Tracción"/>{ConfigBusiness.get('moviles.traccion.obligatorio') === 'true' ? requiredSymbol : ' ' }:
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('MOVILES_MODIFICAR_DATOS_GENERALES')) ? (
                              <div className="form-control-static col-form-label form-value">{formData.traccion ? TIPOS_TRACCION[formData.traccion]['label'] : ''}</div>
                              ) : (
                              <div>
                                <Select
                                  id="traccion"
                                  name="traccion"
                                  placeholder={!state.traccionLoading ? this.props.intl.formatMessage({ id: 'movilesAbm.render.general_data.placeholder_combustible', defaultMessage: 'Seleccione' }) : ''}
                                  options={Object.values(TIPOS_TRACCION)}
                                  valueKey='id'
                                  labelKey='label'
                                  value={formData.traccion}
                                  onChange={(e) => this.handleFormChangeSelectString("traccion", e)}
                                  isLoading={state.traccionLoading}
                                  disabled={state.traccionLoading}
                                  className={this.state.validationErrors.traccion ? 'is-invalid' : ''}
                                  />
                                  <small className="text-danger">{this.state.validationErrors.traccion}</small>
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {/* PLAN DE MANTENIMIENTO PREVENTIVO */}
                        <div className="col-md-6">
                          <div className="form-group row" >
                            <label className="col-md-3 label-control col-form-label" htmlFor="planPreventivo">
                              <FormattedMessage id="movilesAbm.render.general_data.label_plan_mant_preventivo" defaultMessage="Plan Mant. Preventivo"/>{ConfigBusiness.get('moviles.planMantPreventivo.obligatorio') === 'true' ? requiredSymbol : ' ' }:
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('MOVILES_MODIFICAR_DATOS_GENERALES')) ? (
                              <div className="form-control-static col-form-label form-value">{this.state.formData.planPreventivo ? this.state.formData.planPreventivo.descripcion : ''}</div>
                              ) : (
                              <div>
                                <Select.Async
                                    name="planPreventivo"
                                    id="planPreventivo"
                                    placeholder={this.props.intl.formatMessage({ id: 'movilesAbm.render.general_data.placeholder_plan_mant_preventivo', defaultMessage: 'Seleccione' })}
                                    value={this.state.formData.planPreventivo}
                                    valueKey="id"
                                    labelKey="descripcion"
                                    cacheOptions={false}
                                    loadOptions={this.getPlanPreventivos}
                                    onChange={this.handlePlanPreventivoChange}
                                    isLoading={state.planesPreventivosLoading}
                                    disabled={this.props.action === "VIEW" || !this.state.formData.unidadMedidorId}
                                    key={this.state.formData.unidadMedidorId}
                                    className={this.state.validationErrors.planPreventivo ? 'is-invalid' : ''}
                                  />
                                  <small className="text-danger">{this.state.validationErrors.planPreventivo}</small>
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* PLAN DE MANTENIMIETO VENCIMIENTO */}
                        <div className="col-md-6">
                          <div className="form-group row" style={{display: ConfigBusiness.get('planesMantenimiento.vencimiento.habilitado') === 'true' ? '' : 'none' }}>
                            <label className="col-md-3 label-control col-form-label" htmlFor="planVencimiento">
                              <FormattedMessage id="movilesAbm.render.general_data.label_plan_mant_vencimiento" defaultMessage="Plan Mant. Vencimiento"/>:
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('MOVILES_MODIFICAR_DATOS_GENERALES')) ? (
                              <div className="form-control-static col-form-label form-value">{this.state.formData.planVencimiento ? this.state.formData.planVencimiento.descripcion : ''}</div>
                              ) : (
                              <div>
                                <Select
                                  id="planVencimiento"
                                  name="planVencimiento"
                                  placeholder={this.props.intl.formatMessage({ id: 'movilesAbm.render.general_data.placeholder_plan_mant_vencimiento"', defaultMessage: 'Seleccione' })}
                                  options={this.state.planesVencimiento}
                                  valueKey='value'
                                  labelKey='label'
                                  value={planVencimiento ? { value: planVencimiento.id, label: planVencimiento.label } : null}
                                  onChange={(e) => this.handleFormChangeSelectObject("planVencimiento", e)}
                                  isLoading={state.planesVencimientosLoading}
                                  disable={state.planesVencimientosLoading}
                                />
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {/* COMBUSTIBLE */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="combustible">
                              <FormattedMessage id="movilesAbm.render.general_data.label_combustible" defaultMessage="Combustible"/>{ConfigBusiness.get('moviles.movilCombustibleTipo.obligatorio') === 'true' ? requiredSymbol : ' ' }:
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('MOVILES_MODIFICAR_DATOS_GENERALES')) ? (
                              <div className="form-control-static col-form-label form-value">{formData.movilCombustibleTipo ? formData.movilCombustibleTipo.descripcion : ''}</div>
                              ) : (
                              <div>
                                <Select
                                  id="moviles_combustible_tipo_id"
                                  name="moviles_combustible_tipo_id"
                                  placeholder={!state.combustiblesLoading ? this.props.intl.formatMessage({ id: 'movilesAbm.render.general_data.placeholder_combustible', defaultMessage: 'Seleccione' }) : ''}
                                  options={state.movilCombustibleTipos}
                                  valueKey='value'
                                  labelKey='label'
                                  value={movilCombustibleTipo ? { value: movilCombustibleTipo.id, label: movilCombustibleTipo.label } : null}
                                  onChange={(e) => this.handleFormChangeSelectObject("movilCombustibleTipo", e)}
                                  isLoading={state.combustiblesLoading}
                                  disabled={state.combustiblesLoading}
                                  className={this.state.validationErrors.movilCombustibleTipo ? 'is-invalid' : ''}
                                />
                                <small className="text-danger">{this.state.validationErrors.movilCombustibleTipo}</small>
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                           {/* RENDIMIENTO PROPIO */}
                        <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label text-break overflow-wrap-normal">
                              <FormattedMessage id="movilesAbm.render.rendimientoPropio_movil.label_rendimientoPropio" defaultMessage="Rendimiento Propio"/>:
                            </label>
                            <div className="col-md-2 mt-1">
                              <Switch
                                onChange={ ()=>this.handleChangePropio('rendimientoPropio',!formData.rendimientoPropio) }
                                checked={formData.rendimientoPropio ? formData.rendimientoPropio : false}
                                id="rendimientoPropio"
                                name="rendimientoPropio"
                                disabled={this.state.props.action === 'VIEW' || !Security.hasPermission('MOVILES_MODIFICAR')}
                                offColor="#FF4961"
                                onColor="#28D094"
                              />
                            </div>
                            {/* RENDIMIENTO ESPERADO */}
                            <label className="col-md-2 label-control col-form-label text-break overflow-wrap-normal">
                              <FormattedMessage id="movilesAbm.render.rendimientoEsperado_movil.label_rendimientoEsperado" defaultMessage="Rendimiento Esperado"/>:
                            </label>
                            <div className="col-md-5 mt-auto">
                            {(this.props.action === 'VIEW' || !Security.hasPermission('MOVILES_MODIFICAR')) ? (
                              <div className="form-control-static col-form-label form-value">{formData.rendimientoEsperado ? formData.rendimientoEsperado : formData.rendimientoEsperadoModelo}</div>
                              ) : (
                              <div>
                                <input type="text" className={this.state.validationErrors.rendimientoEsperado ? "form-control is-invalid" : "form-control"} id="rendimientoEsperado" name="rendimientoEsperado" placeholder="" value={formData.rendimientoPropio ? formData.rendimientoEsperado : formData.rendimientoEsperadoModelo} onChange={this.handleInputFormChange} disabled={formData.rendimientoPropio == false}/>
                                <small className="text-danger">{this.state.validationErrors.rendimientoEsperado}</small>
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                        <div className="row">
                          <div className="col-md-6">
                          { (Security.hasPermission('LLANTAS_MODELOS_VISUALIZAR') || Security.hasPermission('LLANTAS_MODELOS_LISTAR')) ?
                            <div className="form-group row">
                            {/* CLASE */}
                              <label className="col-md-3 label-control col-form-label" htmlFor="clase">
                                <FormattedMessage id="movilesAbm.render.general_data.label_clase" defaultMessage="Clase"/>:
                              </label>
                              <div className="col-md-9">
                                {this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('MOVILES_MODIFICAR_DATOS_GENERALES')) ? (
                                <div className="form-control-static col-form-label form-value">{this.state.formData.claseEloquent ? this.state.formData.claseEloquent.nombre : ''}</div>
                                ) : (
                                <div>
                                  <Select
                                    id="clase"
                                    name="clase"
                                    placeholder={!state.combustiblesLoading ? this.props.intl.formatMessage({ id: 'movilesAbm.render.general_data.placeholder_combustible', defaultMessage: 'Seleccione' }) : ''}
                                    options={this.state.clases}
                                    valueKey='value'
                                    labelKey='nombre'
                                    value={this.state.formData.claseEloquent ? this.state.formData.claseEloquent : null}
                                    onChange={(e) => this.handleFormChangeSelectObject("claseEloquent", e)}
                                    isLoading={state.combustiblesLoading}
                                    disabled={state.combustiblesLoading}
                                    className={this.state.validationErrors.claseEloquent ? 'is-invalid' : ''}
                                  />
                                  <small className="text-danger">{this.state.validationErrors.claseEloquent}</small>
                                </div>
                                )}
                              </div>
                            </div>
                          : ''}
                          </div>
                          <div className="col-md-6">
                           {/* TANQUE PROPIO */}
                        <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label text-break overflow-wrap-normal">
                              <FormattedMessage id="movilesAbm.render.tanquePropio_movil.label_tanquePropio" defaultMessage="Tanque Propio"/>:
                            </label>
                            <div className="col-md-2 mt-1">
                              <Switch
                                onChange={ ()=>this.handleChangePropio('tanquePropio',!formData.tanquePropio) }
                                checked={formData.tanquePropio ? formData.tanquePropio : false}
                                id="tanquePropio"
                                name="tanquePropio"
                                disabled={this.state.props.action === 'VIEW' || !Security.hasPermission('MOVILES_MODIFICAR')}
                                offColor="#FF4961"
                                onColor="#28D094"
                              />
                            </div>
                            {/* CAPACIDAD TANQUE */}
                            <label className="col-md-2 label-control col-form-label text-break overflow-wrap-normal">
                              <FormattedMessage id="movilesAbm.render.capacidadTanque_movil.label_capacidadTanque" defaultMessage="Capacidad Tanque"/>:
                            </label>
                            <div className="col-md-5 mt-auto">
                            {(this.props.action === 'VIEW' || !Security.hasPermission('MOVILES_MODIFICAR')) ? (
                              <div className="form-control-static col-form-label form-value">{formData.tanquePropio ? formData.capacidadTanque : formData.capacidadTanqueModelo}</div>
                              ) : (
                              <div>
                                <input type="text" className={this.state.validationErrors.capacidadTanque ? "form-control is-invalid" : "form-control"} id="capacidadTanque" name="capacidadTanque" placeholder="" value={formData.tanquePropio ? formData.capacidadTanque : formData.capacidadTanqueModelo} onChange={this.handleInputFormChange} disabled={formData.tanquePropio == false}/>
                                <small className="text-danger">{this.state.validationErrors.capacidadTanque}</small>
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                        </div>
                      <div className="row">
                        {/* NRO. REGISTRO (DNRPA) */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="dnrpaSeccional">
                              <FormattedMessage id="movilesAbm.render.general_data.label_nro_registro" defaultMessage="Nro. Registro (DNRPA)"/>{ConfigBusiness.get('moviles.dnrpaSeccional.obligatorio') === 'true' ? requiredSymbol : ' ' }:
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('MOVILES_MODIFICAR_DATOS_GENERALES')) ? (
                              <div className="form-control-static col-form-label form-value">{this.state.formData.dnrpaSeccional ? this.state.formData.dnrpaSeccional.codigoAutomotor + ' - ' + this.state.formData.dnrpaSeccional.denominacion : ''}</div>
                              ) : (
                              <div>
                                <Select
                                  id="dnrpaSeccional"
                                  name="dnrpaSeccional"
                                  placeholder={!state.dnrpaSeccionalesLoading ? this.props.intl.formatMessage({ id: 'movilesAbm.render.general_data.placeholder_nro_registro', defaultMessage: 'Seleccione' }) : ''}
                                  options={this.state.dnrpaSeccionales}
                                  valueKey='value'
                                  labelKey='label'
                                  value={dnrpaSeccional ? { value: dnrpaSeccional.id, label: dnrpaSeccional.label } : null}
                                  onChange={(e) => this.handleFormChangeSelectObject("dnrpaSeccional", e)}
                                  isLoading={state.dnrpaSeccionalesLoading}
                                  disable={state.dnrpaSeccionalesLoading || this.state.selectDisable}
                                  className={this.state.validationErrors.dnrpaSeccional ? 'is-invalid' : ''}
                                />
                                <small className="text-danger">{this.state.validationErrors.dnrpaSeccional}</small>
                              </div>
                              )}
                            </div>
                          </div>
                        </div>

                        {/* TITULAR */}
                        { ConfigBusiness.get('moviles.titular.habilitado') === 'true' ? (
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="titular">
                              {this.state.labelTitular ? this.state.labelTitular : '' }{ConfigBusiness.get('moviles.titular.obligatorio') === 'true' ? requiredSymbol : ' '}:
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('MOVILES_MODIFICAR_DATOS_GENERALES')) ? (
                              <div className="form-control-static col-form-label form-value">{titular ? titular.label : ''}</div>
                              ) : (
                                // this.state.titularInputType === 'text' ? (
                                //   <div>
                                //     <input type="text" className="form-control" id="titular" name="titular" placeholder="" value={formData.titular} onChange={this.handleInputFormChange} />
                                //   </div>
                                //   ) : (
                                  <div >
                                    <div className="col-md-10" style = {{display: "inline-table", padding: 0 + "px"}}>
                                      <Select
                                        id="titular"
                                        name="titular"
                                        placeholder={!state.titularesLoading ? this.props.intl.formatMessage({ id: 'movilesAbm.render.general_data.placeholder_titular', defaultMessage: 'Seleccione' }) : ''}
                                        options={state.titulares}
                                        valueKey='value'
                                        labelKey='label'
                                        value={titular ? titular : null}
                                        onChange={(e) => this.handleFormChangeSelectTitular(e)}
                                        isLoading={state.titularesLoading}
                                        disabled={state.titularesLoading}
                                        className={this.state.validationErrors.titular ? 'is-invalid' : ''}
                                      />
                                      <small className="text-danger">{this.state.validationErrors.titular}</small>
                                    </div>
                                    <div className="col-md-1" style = {{display: "inline-table",  'paddingRight': 0 + "px"}}>
                                      <button type="button" className="btn btn-primary" onClick={()=>this.handleAgregarTitular()}>+</button>
                                    </div>
                                  </div>
                                  // )
                              )}
                            </div>
                          </div>
                        </div>
                        ):null}
                      </div>
                      <div className="form-group row">
                        {/* COMPAÑÍA ORIGEN */}
                        { ConfigBusiness.get('moviles.companiaOrigen.habilitado') === 'true' ? (
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="companiaOrigen">
                              <FormattedMessage id="movilesAbm.render.general_data.label_cia_origen" defaultMessage="Cía. Origen"/>{ConfigBusiness.get('moviles.companiaOrigen.obligatorio') === 'true' ? requiredSymbol : ' ' }:
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('MOVILES_MODIFICAR_DATOS_GENERALES')) ? (
                              <div className="form-control-static col-form-label form-value">{formData.companiaOrigen ? formData.companiaOrigen : ''}</div>
                              ) : (
                                this.state.companiaOrigenInputType === 'text' ? (
                                <div>
                                  <input type="text" className={this.state.validationErrors.companiaOrigen ? "form-control is-invalid" : "form-control"} id="companiaOrigen" name="companiaOrigen" placeholder="" value={formData.companiaOrigen} onChange={this.handleInputFormChange} />
                                </div>
                                ) : (
                                <div>
                                  <Select
                                    id="companiaOrigen"
                                    name="companiaOrigen"
                                    placeholder={!state.companiasLoading ? this.props.intl.formatMessage({ id: 'movilesAbm.render.general_data.placeholder_cia_origen', defaultMessage: 'Seleccione' }) : ''}
                                    options={this.state.companiasOrigen}
                                    valueKey='value'
                                    labelKey='label'
                                    value={companiaOrigen ? { value: companiaOrigen.id, label: companiaOrigen.label } : null}
                                    onChange={(e) => this.handleFormChangeSelectString("companiaOrigen", e)}
                                    isLoading={state.companiasLoading}
                                    disabled={state.companiasLoading}
                                    className={this.state.validationErrors.companiaOrigen ? 'is-invalid' : ''}
                                  />
                                </div>
                                )
                                )}
                                <small className="text-danger">{this.state.validationErrors.companiaOrigen}</small>
                            </div>
                          </div>
                        </div>
                        ) : null}
                        {/* ACTIVO FIJO */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="activoFijo">
                              <FormattedMessage id="movilesAbm.render.general_data.label_activo_fijo" defaultMessage="Activo Fijo"/>:
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('MOVILES_MODIFICAR_DATOS_GENERALES')) ? (
                              <div className="form-control-static col-form-label form-value">{formData.activoFijo ? formData.activoFijo : ''}</div>
                              ) : (
                              <div>
                                <input type="text" className={this.state.validationErrors.activoFijo ? "form-control is-invalid" : "form-control"} id="activoFijo" name="activoFijo" placeholder="" value={formData.activoFijo} onChange={this.handleInputFormChange} />
                                <small className="text-danger">{this.state.validationErrors.activoFijo}</small>
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {/* NRO TITULO */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="nroTitulo">
                              <FormattedMessage id="movilesAbm.render.general_data.label_nro_de_titulo" defaultMessage="Nro. de Título"/>:
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('MOVILES_MODIFICAR_DATOS_GENERALES')) ? (
                              <div className="form-control-static col-form-label form-value">{formData.nroTitulo ? formData.nroTitulo : ''}</div>
                              ) : (
                              <div>
                                <input type="text" className="form-control" id="nroTitulo" name="nroTitulo" placeholder="" value={formData.nroTitulo ? formData.nroTitulo : ''} onChange={this.handleInputFormChange} />
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* VALOR POLIZA */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="valorPoliza">
                              <FormattedMessage id="movilesAbm.render.general_data.label_valor_poliza" defaultMessage="Valor de Poliza"/>:
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('MOVILES_MODIFICAR_DATOS_GENERALES')) ? (
                              <div className="form-control-static col-form-label form-value">{formData.valorPoliza ? formData.valorPoliza : null}</div>
                              ) : (
                              <div>
                                <input className={this.state.validationErrors.valorPoliza ? "form-control is-invalid" : "form-control"} id="valorPoliza" name="valorPoliza" placeholder="" value={formData.valorPoliza} onChange={this.handleInputFormChange} />
                              </div>
                              )}
                              <small className="text-danger">{this.state.validationErrors.valorPoliza}</small>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {/* FUNCION */}
                        <div className="col-md-6">
                        <div className="form-group row" style={{display: ConfigBusiness.get('moviles.funcion.habilitado') === 'true' ? '' : 'none' }}>
                            <label className="col-md-3 label-control col-form-label" htmlFor="funcion">
                            <FormattedMessage id="movilesAbm.render.general_data.label_funcion" defaultMessage="Funcion "/>:
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('MOVILES_MODIFICAR_DATOS_GENERALES')) ? (
                              <div className="form-control-static col-form-label form-value">{this.state.formData.funcion ? this.state.formData.funcion.nombre : ''}</div>
                              ) : (
                                  <div >
                                    <div className="col-md-10" style = {{display: "inline-table", padding: 0 + "px"}}>
                                      <Select
                                        id="funcion"
                                        name="funcion"
                                        placeholder={this.props.intl.formatMessage({ id: 'movilesAbm.render.general_data.placeholder_funcion', defaultMessage: 'Seleccione' })}
                                        options={state.funciones}
                                        valueKey='id'
                                        labelKey='nombre'
                                        value={this.state.formData.funcion ? this.state.formData.funcion : null}
                                        onChange={(e) => this.handleFormChangeSelectFuncion(e)}
                                      />
                                    </div>
                                    <div className="col-md-1" style = {{display: "inline-table",  'paddingRight': 0 + "px"}}>
                                      <button type="button" className="btn btn-primary" onClick={()=>this.handleAgregarFuncion()}>+</button>
                                    </div>
                                  </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">

                        {/* FECHA DE ALTA */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="fechaAlta">
                              <FormattedMessage id="movilesAbm.render.general_data.label_fecha_de_alta" defaultMessage="Fecha de Alta"/>{ConfigBusiness.get('moviles.fechaAlta.obligatorio') === 'true' ? requiredSymbol : ' ' }:
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('MOVILES_MODIFICAR_DATOS_GENERALES')) ? (
                              <div className="form-control-static col-form-label form-value">{formData.fechaAlta === null || formData.fechaAlta === '0000-00-00' ? '' : moment(formData.fechaAlta).format('L')}</div>
                              ) : (
                              <div>
                                <DatePicker
                                  id="fechaAlta"
                                  name="fechaAlta"
                                  className={this.state.validationErrors.fechaAlta ? 'form-control date-picker-placeholder is-invalid' : 'form-control date-picker-placeholder'}
                                  placeholderText={this.props.intl.formatMessage({ id: 'movilesAbm.render.general_data.placeholder_fecha_de_alta', defaultMessage: 'DD/MM/AAAA' })}
                                  selected={formData.fechaAlta === null || formData.fechaAlta === '0000-00-00' ? null : moment(formData.fechaAlta) }
                                  onChange={(event) => this.handleDatePickerFormChange("fechaAlta", event)}
                                  minDate={moment('01-01-1901')}
                                  maxDate={moment()}
                                  onKeyDown={(e) => e.preventDefault()}
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  />
                              </div>
                              )}
                              <small className="text-danger">{this.state.validationErrors.fechaAlta}</small>
                            </div>
                          </div>
                        </div>
                        {/* FECHA FIN GARANTIA */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="fechaFinGarantia">
                              <FormattedMessage id="movilesAbm.render.general_data.label_fecha_fin_garantia" defaultMessage="Fecha Fin de Garantía"/>{ConfigBusiness.get('moviles.fechaFinGarantia.obligatorio') === 'true' ? requiredSymbol : ' ' }:
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('MOVILES_MODIFICAR_DATOS_GENERALES')) ? (
                              <div className="form-control-static col-form-label form-value">{formData.fechaFinGarantia === null || formData.fechaFinGarantia === '0000-00-00' ? '' : moment(formData.fechaFinGarantia).format('L') }</div>
                              ) : (
                              <div>
                                <DatePicker
                                  id="fechaFinGarantia"
                                  name="fechaFinGarantia"
                                  className={this.state.validationErrors.fechaFinGarantia ? 'form-control date-picker-placeholder is-invalid' : 'form-control date-picker-placeholder'}
                                  placeholderText={this.props.intl.formatMessage({ id: 'movilesAbm.render.general_data.placeholder_fecha_fin_garantia', defaultMessage: 'DD/MM/AAAA' })}
                                  selected={formData.fechaFinGarantia === null || formData.fechaFinGarantia === '0000-00-00' ? null : moment(formData.fechaFinGarantia) }
                                  onChange={(event) => this.handleDatePickerFormChange("fechaFinGarantia", event)}
                                  onKeyDown={(e) => e.preventDefault()}
                                  minDate={moment('01-01-1901')}
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                />
                              </div>
                              )}
                              <small className="text-danger">{this.state.validationErrors.fechaFinGarantia}</small>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {/* FECHA DE BAJA */}
                        <div className="col-md-6">
                          {this.props.action === 'VIEW' || this.props.action === 'EDIT' ? (
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="fechaBaja">
                              <FormattedMessage id="movilesAbm.render.general_data.label_fecha_de_baja" defaultMessage="Fecha de Baja"/>:
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('MOVILES_MODIFICAR_DATOS_GENERALES')) ? (
                              <div className="form-control-static col-form-label form-value">{formData.fechaBaja === null || formData.fechaBaja === '0000-00-00' ? '' : moment(formData.fechaBaja).format('L') }</div>
                              ) : (
                                this.props.action === 'EDIT' ? (
                                <div>
                                  <DatePicker
                                    id="fechaBaja"
                                    name="fechaBaja"
                                    className={this.state.validationErrors.fechaBaja ? 'form-control date-picker-placeholder is-invalid' : "form-control date-picker-placeholder"}
                                    placeholderText={this.props.intl.formatMessage({ id: 'movilesAbm.render.general_data.placeholder_fecha_de_baja', defaultMessage: 'DD/MM/AAAA' })}
                                    selected={formData.fechaBaja === null || formData.fechaBaja === '0000-00-00' ? null : moment(formData.fechaBaja) }
                                    onChange={(event) => this.handleDatePickerFormChange("fechaBaja", event)}
                                    maxDate={moment()}
                                    minDate={moment(formData.fechaAlta ? formData.fechaAlta : '')}
                                    onKeyDown={(e) => e.preventDefault()}
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                  />
                                </div>
                                ) : (
                                <div></div>
                                )
                              )}
                            <small className="text-danger">{this.state.validationErrors.fechaBaja}</small>
                            </div>
                          </div>
                          ) : (
                            <div></div>
                          )}
                        </div>
                         {/* FECHA INICIO OPERACION */}
                         <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="fechaInicioOperacion">
                              <FormattedMessage id="movilesAbm.render.general_data.label_fecha_inicio_operacion" defaultMessage="Fecha Inicio Operacion"/>:
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('MOVILES_MODIFICAR_DATOS_GENERALES')) ? (
                              <div className="form-control-static col-form-label form-value">{formData.fechaInicioOperacion === null || formData.fechaInicioOperacion === '0000-00-00' ? '' : formData.fechaInicioOperacion}</div>
                              ) : (
                              <div>
                                <DatePicker
                                  id="fechaInicioOperacion"
                                  name="fechaInicioOperacion"
                                  className="form-control date-picker-placeholder"
                                  placeholderText={this.props.intl.formatMessage({ id: 'movilesAbm.render.general_data.placeholder_fecha_inicio_operacion', defaultMessage: 'DD/MM/AAAA' })}
                                  selected={formData.fechaInicioOperacion === null || formData.fechaInicioOperacion === '0000-00-00' ? null : moment(formData.fechaInicioOperacion)}
                                  onChange={(event) => this.handleDatePickerFormChange("fechaInicioOperacion", event)}
                                  onKeyDown={(e) => e.preventDefault()}
                                  minDate={moment('01-01-1901')}
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                />
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {/* FECHA FIN DE CONTRATO */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="fechaFinContrato">
                              <FormattedMessage id="movilesAbm.render.general_data.label_fecha_fin_contrato" defaultMessage="Fecha Fin de Contrato"/>:
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('MOVILES_MODIFICAR_DATOS_GENERALES')) ? (
                              <div className="form-control-static col-form-label form-value">{formData.fechaFinContrato === null || formData.fechaFinContrato === '0000-00-00' ? '' : moment(formData.fechaFinContrato).format('L') }</div>
                              ) : (
                              <div>
                                <DatePicker
                                  id="fechaFinContrato"
                                  name="fechaFinContrato"
                                  className={this.state.validationErrors.fechaFinContrato ? 'form-control date-picker-placeholder is-invalid' : 'form-control date-picker-placeholder'}
                                  placeholderText={this.props.intl.formatMessage({ id: 'movilesAbm.render.general_data.placeholder_fecha_de_alta', defaultMessage: 'DD/MM/AAAA' })}
                                  selected={formData.fechaFinContrato === null || formData.fechaFinContrato === '0000-00-00' ? null : moment(formData.fechaFinContrato) }
                                  onChange={(event) => this.handleDatePickerFormChange("fechaFinContrato", event)}
                                  minDate={moment(formData.fechaAlta ? formData.fechaAlta : '' )}
                                  onKeyDown={(e) => e.preventDefault()}
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                />
                              </div>
                              )}
                              <small className="text-danger">{this.state.validationErrors.fechaFinContrato}</small>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                        </div>
                        {/* BOTON DATOS GENERALES */}
                        <div className="col-md-6">
                          <div className="row justify-content-end">
                          {Security.renderIfHasPermission('MOVILES_DATOS_GENERALES_HISTORICO_LISTAR', (
                            <div className="col-md-4">
                              {this.props.action === 'VIEW' || this.props.action === 'EDIT' ? (
                                <button type="button" className="btn btn-fleet float-right" data-toggle="modal" data-target="#datosGenerales_modal" onClick={()=>this.handleSetTipo('datosGenerales')}>
                                <i className="la la-search align-middle"></i>
                                <FormattedMessage id="movilesAbm.render.administracion.datosGenerales_movil" defaultMessage="Histórico D. Generales"/>
                                </button>
                                ) : (
                                <div style={{display:'none'}}></div>
                              )}
                            </div>
                          ))}
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                <div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body">
                      <h4 className="form-section mt-2">
                        <i className="ft-users"></i> <FormattedMessage id="movilesAbm.render.choferes.header_choferes" defaultMessage="Choferes"/>
                      </h4>
                      <div className="row">
                        {/* RESPONSABLE 1 */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="responsable1">
                              <FormattedMessage id="movilesAbm.render.choferes.label_responsable1" defaultMessage="Responsable 1"/>:
                            </label>
                            <div className="col-md-9">
                              <div>
                                <Select.Async
                                  name="responsable1"
                                  placeholder={this.props.intl.formatMessage({ id: 'movilesAbm.render.choferes.placeholder_responsable1', defaultMessage: 'Seleccione' })}
                                  value={this.state.formData.responsable1}
                                  valueKey="id"
                                  labelKey="label"
                                  loadOptions={this.getBackendPersonasPorBase}
                                  onChange={e => this.handleChange("responsable1", e)}
                                  disabled={(this.state.disableAvlFields && this.props.action === 'EDIT' ) || this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('MOVILES_MODIFICAR_CHOFERES'))}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* RESPONSABLE 2 */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="responsable2">
                              <FormattedMessage id="movilesAbm.render.choferes.label_responsable2" defaultMessage="Responsable 2"/>:
                            </label>
                            <div className="col-md-9">
                              <div>
                                <Select.Async
                                  name="responsable2"
                                  placeholder={this.props.intl.formatMessage({ id: 'movilesAbm.render.choferes.placeholder_responsable2', defaultMessage: 'Seleccione' })}
                                  value={this.state.formData.responsable2}
                                  valueKey="id"
                                  labelKey="label"
                                  loadOptions={this.getBackendPersonasPorBase}
                                  onChange={e => this.handleChange("responsable2", e)}
                                  disabled={this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('MOVILES_MODIFICAR_CHOFERES'))}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {/* SUPERVISOR */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="supervisor">
                              <FormattedMessage id="movilesAbm.render.choferes.label_supervisor" defaultMessage="Supervisor"/>:
                            </label>
                            <div className="col-md-9">
                              <div>
                                <Select.Async
                                  name="supervisor"
                                  placeholder={this.props.intl.formatMessage({ id: 'movilesAbm.render.choferes.placeholder_supervisor', defaultMessage: 'Seleccione' })}
                                  value={this.state.formData.supervisor}
                                  valueKey="id"
                                  labelKey="label"
                                  loadOptions={this.getBackendPersonasPorBase}
                                  onChange={e => this.handleChange("supervisor", e)}
                                  disabled={this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('MOVILES_MODIFICAR_CHOFERES'))}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* TEMPORAL */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="temporal">
                              <FormattedMessage id="movilesAbm.render.choferes.label_temporal" defaultMessage="Temporal"/>:
                            </label>
                            <div className="col-md-9">
                              <div>
                                <Select.Async
                                  name="temporal"
                                  placeholder={this.props.intl.formatMessage({ id: 'movilesAbm.render.choferes.placeholder_temporal', defaultMessage: 'Seleccione' })}
                                  value={this.state.formData.temporal}
                                  valueKey="id"
                                  labelKey="label"
                                  loadOptions={this.getBackendPersonasPorBase}
                                  onChange={e => this.handleChange("temporal", e)}
                                  disabled={this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('MOVILES_MODIFICAR_CHOFERES'))}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                        </div>
                        {/* BOTON HISTORICO CHOFERES */}
                        <div className="col-md-6">
                          <div className="row justify-content-end">
                          {Security.renderIfHasPermission('MOVILES_CHOFERES_HISTORICO_LISTAR', (
                            <div className="col-md-4">
                              {this.props.action === 'VIEW' || this.props.action === 'EDIT' ? (
                              <button type="button" className="btn btn-fleet float-right" data-toggle="modal" data-target="#choferes_modal" onClick={()=>this.handleSetTipo('choferes')}>
                              <i className="la la-search align-middle"></i>
                              <FormattedMessage id="movilesAbm.render.administracion.historico_de_choferes_del_movil" defaultMessage="Histórico de Choferes"/>
                              </button>
                              ) : (
                              <div style={{display:'none'}}></div>
                              )}
                            </div>
                          ))}
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                <div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body">
                      <h4 className="form-section mt-2">
                        <i className="la la-cog"></i> <FormattedMessage id="movilesAbm.render.administracion.header_administracion" defaultMessage="Administración"/>
                      </h4>
                      {ConfigBusiness.get('moviles.base.mostrarEnDatosGenerales') === 'false' ? (
                      <div className="row">
                        {/* BASE */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="base">
                              <FormattedMessage id="movilesAbm.render.administracion.label_base" defaultMessage="Base"/>{requiredSymbol}:
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('MOVILES_MODIFICAR_ADMINISTRACION')) ? (
                              <div className="form-control-static col-form-label form-value">{formData.base && formData.base.descripcion ? formData.base.descripcion : ''}</div>
                              ) : (
                              <div>
                                <Select
                                  id="base"
                                  name="base"
                                  placeholder={!state.basesLoading ? this.props.intl.formatMessage({ id: 'movilesAbm.render.general_data.placeholder_base', defaultMessage: 'Seleccione' }) : ''}
                                  options={this.state.bases}
                                  valueKey='value'
                                  labelKey='label'
                                  value={base ? { value: base.id, label: base.label } : null}
                                  onChange={(e) => this.handleFormChangeSelectObject("base", e).then(() => { this.getJefeBase() })}
                                  isLoading={state.basesLoading}
                                  disabled={state.basesLoading}
                                />
                                <small className="text-danger">{this.state.validationErrors.base}</small>
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* Jefe BASE */}
                        <div className="col-md-6">
                          <div className="form-group row" style={{display: formData.base ? '' : 'none' }}>
                            <label className="col-md-3 label-control col-form-label" htmlFor="chasis">
                              <FormattedMessage id="movilesAbm.render.administracion.label_jefe_base" defaultMessage="Jefe de Base"/> :
                            </label>
                            <div className="col-md-9">
                              <div className="form-control-static col-form-label form-value">{formData.base ? (formData.base.jefe ? formData.base.jefe.nombre + ' ' + formData.base.jefe.apellido : '') : ''}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      ) : ''}
                      <div className="row">
                        {/* CENTRO DE COSTOS */}
                        {this.state.viewCentroCostos ? (
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="centroCostos">
                              <FormattedMessage id="movilesAbm.render.administracion.label_centro_de_costos" defaultMessage="Centro de costos"/>{ConfigBusiness.get('moviles.centroCostos.obligatorio') === 'true' ? requiredSymbol : ' ' }:
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('MOVILES_MODIFICAR_ADMINISTRACION')) ? (
                              <div className="form-control-static col-form-label form-value">{formData.centroCostos && formData.centroCostos.nombre ? formData.centroCostos.nombre : ''}</div>
                              ) : (
                              <div>
                                <Select
                                  id="centroCostos"
                                  name="centroCostos"
                                  placeholder={!state.centrosCostosLoading ? this.props.intl.formatMessage({ id: 'movilesAbm.render.general_data.placeholder_centro_de_costos', defaultMessage: 'Seleccione' }) : ''}
                                  options={this.state.centrosCostos}
                                  valueKey='value'
                                  labelKey='label'
                                  value={centroCostos ? { value: centroCostos.id, label: centroCostos.label } : null}
                                  onChange={this.props.action === 'ADD' ? (e) => this.handleFormChangeSelectObject("centroCostos", e) : (e) => this.handleChangeCentroCosto("centroCostos", e, this.state.centrosCostos)}
                                  isLoading={state.centrosCostosLoading}
                                  disabled={state.centrosCostosLoading}
                                  className={this.state.validationErrors.centroCostos ? 'is-invalid' : ''}
                                  clearable={false}
                                />
                                <small className="text-danger">{this.state.validationErrors.centroCostos}</small>
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                        ) : null}
                        {/* CERTIFICADO VTV */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="certificadoVtv">
                              <FormattedMessage id="movilesAbm.render.administracion.label_certificado_VTV" defaultMessage="Certificado VTV"/>:
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('MOVILES_MODIFICAR_ADMINISTRACION')) ? (
                              <div className="form-control-static col-form-label form-value">{formData.certificadoVtv ? formData.certificadoVtv : ''}</div>
                              ) : (
                              <div>
                                <input type="text" className="form-control" id="certificadoVtv" name="certificadoVtv" placeholder="" value={formData.certificadoVtv} onChange={this.handleInputFormChange} />
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {/* DIVISION */}
                        {ConfigBusiness.get('moviles.trabajaConDivisiones.habilitado') === 'true' ? (
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="division">
                              <FormattedMessage id="División" defaultMessage="División"/>*:
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('MOVILES_MODIFICAR_ADMINISTRACION')) ? (
                              <div className="form-control-static col-form-label form-value">{formData.division && formData.division.nombre ? formData.division.nombre : ''}</div>
                              ) : (
                              <div>
                                <Select
                                  id="division"
                                  name="division"
                                  placeholder={!state.centrosCostosLoading ? this.props.intl.formatMessage({ id: 'Seleccione', defaultMessage: 'Seleccione' }) : ''}
                                  options={this.state.divisiones}
                                  valueKey='value'
                                  labelKey='label'
                                  value={division ? {value: division.value, label: division.label} : null }
                                  onChange={(e) => this.handleFormChangeSelectObject("division", e)}
                                  isLoading={state.centrosCostosLoading}
                                  disabled={state.centrosCostosLoading}
                                />
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                        ) : null}
                      </div>
                      <div className="row">
                        {/* POLIZA */}
                        {this.state.viewPoliza ? (
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="poliza">
                              <FormattedMessage id="movilesAbm.render.administracion.label_poliza" defaultMessage="Póliza"/>:
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('MOVILES_MODIFICAR_ADMINISTRACION')) ? (
                              <div className="form-control-static col-form-label form-value">{formData.poliza ? formData.poliza : ''}</div>
                              ) : (
                              <div>
                                <input type="text" className="form-control" id="poliza" name="poliza" placeholder="" value={formData.poliza ? formData.poliza : ''} onChange={this.handleInputFormChange} />
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                        ) : null}
                        {/* YPF RUTA | TARJETA COMBUSTIBLE ADD */}
                        {this.state.viewYpfRuta && this.props.action !== 'EDIT' ? (
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="ypfRuta">
                              <FormattedMessage id="movilesAbm.render.administracion.label_YPF_ruta" defaultMessage="YPF Ruta"/>:
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('MOVILES_MODIFICAR_ADMINISTRACION')) ? (
                              <div className="form-control-static col-form-label form-value">{formData.ypfRuta ? formData.ypfRuta : ''}</div>
                              ) : (
                              <div>
                                <input type="text" className="form-control" id="ypfRuta" name="ypfRuta" placeholder="" value={formData.ypfRuta ? formData.ypfRuta : ''} onChange={this.handleInputFormChange} />
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                        ) : null}
                        {/* YPF RUTA | TARJETA COMBUSTIBLE EDIT */}
                        {this.state.viewYpfRuta && this.props.action === 'EDIT' ? (
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="ypfRuta">
                              <FormattedMessage id="movilesAbm.render.administracion.label_YPF_ruta" defaultMessage="YPF Ruta"/>:
                            </label>
                            <div className="col-md-9">
                              {(this.props.action === 'EDIT' && !Security.hasPermission('MOVILES_MODIFICAR_ADMINISTRACION')) ? (
                              <div className="form-control-static col-form-label form-value">{formData.ypfRuta ? formData.ypfRuta : ''}</div>
                              ) : (
                              <div>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="ypfRuta"
                                  name="ypfRuta"
                                  value={formData.ypfRuta ? formData.ypfRuta : ''}
                                  onChange={this.handleInputFormChange}
                                  onBlur={this.handleChangeTarjetaCombustible}
                                />
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                        ) : null}
                      </div>
                      <div className="row">
                        {/* TELEPEAJE */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="telepeaje">
                              <FormattedMessage id="movilesAbm.render.administracion.label_telepeaje" defaultMessage="Telepeaje"/>:
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('MOVILES_MODIFICAR_ADMINISTRACION')) ? (
                              <div className="form-control-static col-form-label form-value">{formData.telepeaje ? formData.telepeaje : ''}</div>
                              ) : (
                              <div>
                                <input type="text" className="form-control" id="telepeaje" name="telepeaje" placeholder="" value={formData.telepeaje ? formData.telepeaje : ''} onChange={this.handleInputFormChange} />
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* PROVEEDOR GPS */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="proveedorGps">
                              <FormattedMessage id="movilesAbm.render.administracion.label_proveedor_GPS" defaultMessage="Proveedor GPS"/>:
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('MOVILES_MODIFICAR_ADMINISTRACION')) ? (
                              <div className="form-control-static col-form-label form-value">{formData.proveedorGps ? formData.proveedorGps.razon_social : ''}</div>
                              ) : (
                              <div>
                                <Select
                                  id="proveedorGps"
                                  name="proveedorGps"
                                  placeholder={!state.proveedoresGpsLoading ? this.props.intl.formatMessage({ id: 'movilesAbm.render.general_data.placeholder_proveedorGps', defaultMessage: 'Seleccione' }) : ''}
                                  options={this.state.proveedoresGps}
                                  valueKey='value'
                                  labelKey='label'
                                  value={proveedorGps ? { value: proveedorGps.id, label: proveedorGps.label } : null}
                                  onChange={(e) => this.handleFormChangeSelectObject("proveedorGps", e)}
                                  isLoading={state.proveedoresGpsLoading}
                                  disabled={state.proveedoresGpsLoading}
                                />
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {/* KM ACTUAL */}
                        <div className="col-md-6">
                          <div className="form-group row">
                          { formData.unidadMedidorId && UNIDAD_MEDIDOR[formData.unidadMedidorId]['label'] === 'ODOMETRO' ? (
														<label className="col-md-3 label-control col-form-label" htmlFor="kmActual">
                              <FormattedMessage id="movilesAbm.render.administracion.label_km_actual" defaultMessage="Km Actual"/>{ConfigBusiness.get('moviles.kmActual.obligatorio') === 'true' ? requiredSymbol : ' ' }:
														</label>
                          ):''}
                          { formData.unidadMedidorId && UNIDAD_MEDIDOR[formData.unidadMedidorId]['label'] === 'HOROMETRO' ? (
                            <label className="col-md-3 label-control col-form-label" htmlFor="kmActual">
                            <FormattedMessage id="correctivosAbm.datos_generales.label_horometro_actual" defaultMessage="Horometro Actual"/>{ConfigBusiness.get('moviles.kmActual.obligatorio') === 'true' ? requiredSymbol : ' ' }:
                          </label>
                          ):''}
														<div className="col-md-9">
                            {this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('MOVILES_MODIFICAR_KM')) ? (
                              <div className="form-control-static col-form-label form-value">{formData.kmActual ? (Math.floor(formData.kmActual)).toLocaleString('de-DE') + (formData.kmActual === 0 ? ' km' : ' kms') : 0 + ' km'}</div>
                              ) : (
                                <div>
                                  <input 
                                  type="text" 
                                  className={this.state.validationErrors.kmActual ? 'form-control is-invalid' : 'form-control'} 
                                  id="kmActual" 
                                  name="kmActual" 
                                  placeholder="" 
                                  value={formData.kmActual ? formData.kmActual : '' } 
                                  onChange={(e)=>this.handleChange('kmActual', e.target.value)} 
                                  onBlur={this.handleKmActualChange} />
                                  
                                  <small className="text-danger">{this.state.validationErrors.kmActual}</small>
                                </div>
                              )}
                               </div>
                          
                          </div>
                        </div>
                        {/* IMEI */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="imei">
                              <FormattedMessage id="movilesAbm.render.administracion.label_IMEI" defaultMessage="IMEI"/> :
                            </label>
                            <div className="col-md-9">
                              <div className="form-control-static col-form-label form-value">{formData.imei ? formData.imei : ''}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {/* CEBE */}
                        {this.state.viewCebe ? (
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="cebe">
                              <FormattedMessage id="movilesAbm.render.administracion.label_cebe" defaultMessage="Cebe"/>{ConfigBusiness.get('moviles.cebe.obligatorio') === 'true' ? requiredSymbol : ' ' }:
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('MOVILES_MODIFICAR_ADMINISTRACION')) ? (
                              <div className="form-control-static col-form-label form-value">{formData.cebe ? formData.cebe.nombre : ''}</div>
                              ) : (
                              <div>
                                <Select
                                  id="cebe"
                                  name="cebe"
                                  placeholder={!state.cebesLoading ? this.props.intl.formatMessage({ id: 'movilesAbm.render.general_data.placeholder_cebe', defaultMessage: 'Seleccione' }) : ''}
                                  options={this.state.cebes}
                                  valueKey='value'
                                  labelKey='label'
                                  value={cebe ? { value: cebe.id, label: cebe.label } : null}
                                  onChange={(e) => this.handleFormChangeSelectObject("cebe", e)}
                                  isLoading={state.cebesLoading}
                                  disabled={state.cebesLoading}
                                  className={this.state.validationErrors.cebe ? 'is-invalid':''}
                                />
                                <small className="text-danger">{this.state.validationErrors.cebe}</small>
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                        ) : ''}
                        {/* LUGAR DE GUARDA */}
                        {this.state.viewLugarGuarda ? (
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="lugarGuarda">
                              <FormattedMessage id="movilesAbm.render.administracion.label_lugar_guarda" defaultMessage="Lugar Guarda"/>{ConfigBusiness.get('moviles.lugarGuarda.obligatorio') === 'true' ? requiredSymbol : ' ' }:
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('MOVILES_MODIFICAR_ADMINISTRACION')) ? (
                              <div className="form-control-static col-form-label form-value">{formData.lugarGuarda ? formData.lugarGuarda : ''}</div>
                              ) : (
                              <div>
                                <input type="text" className="form-control" id="lugarGuarda" name="lugarGuarda" placeholder="" value={formData.lugarGuarda ? formData.lugarGuarda : ''} onChange={this.handleInputFormChange} />
                              </div>
                              )}
                            </div>
                          </div>
                        </div>
                        ) : null}
                      </div>

                      {(ConfigBusiness.get('moviles.visualizar_restringido') === 'true' && !Security.hasPermission('MOVILES_VISUALIZAR_ATRIBUTOS_CONTRATO')) ? null : (<div><div className="row">
                        {/* VALOR ADQUISICION */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="valor_adquisicion">
                              <FormattedMessage id="movilesAbm.render.administracion.valor_adquisicion" defaultMessage="Valor de Adquisición"/>{ConfigBusiness.get('moviles.valorAdquisicion.obligatorio') === 'true' ? requiredSymbol : ' '}:
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('MOVILES_MODIFICAR_ADMINISTRACION')) ? (
                              <div className="form-control-static col-form-label form-value">{formData.valorAdquisicion ? formData.valorAdquisicion : ''}</div>
                              ) : (
                                <div>
                                  <input type="text" className={this.state.validationErrors.valorAdquisicion ? "form-control is-invalid" : "form-control"} id="valorAdquisicion" name="valorAdquisicion" placeholder="" value={formData.valorAdquisicion === 0 ? '' : formData.valorAdquisicion} onChange={this.handleInputFormChange} />
                                  <small className="text-danger">{this.state.validationErrors.valorAdquisicion}</small>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* VALOR AMORTIZACION */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="valor_amortizacion">
                              <FormattedMessage id="movilesAbm.render.administracion.valor_amortizacion" defaultMessage="Valor de Amortización"/>{ConfigBusiness.get('moviles.valorAmortizacion.obligatorio') === 'true' ? requiredSymbol : ' '}:
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('MOVILES_MODIFICAR_ADMINISTRACION')) ? (
                              <div className="form-control-static col-form-label form-value">{formData.valorAmortizacion ? formData.valorAmortizacion : ''}</div>
                              ) : (
                                <div>
                                  <input type="text" className={this.state.validationErrors.valorAmortizacion ? "form-control is-invalid" : "form-control"} id="valorAmortizacion" name="valorAmortizacion" placeholder="" value={formData.valorAmortizacion === 0 ? '' : formData.valorAmortizacion} onChange={this.handleInputFormChange} />
                                  <small className="text-danger">{this.state.validationErrors.valorAmortizacion}</small>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        {/* VALOR CONTABLE */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="valor_contable">
                              <FormattedMessage id="movilesAbm.render.administracion.valor_contable" defaultMessage="Valor de Contable"/>{ConfigBusiness.get('moviles.valorContable.obligatorio') === 'true' ? requiredSymbol : ' '}:
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('MOVILES_MODIFICAR_ADMINISTRACION')) ? (
                              <div className="form-control-static col-form-label form-value">{formData.valorContable ? formData.valorContable : ''}</div>
                              ) : (
                                <div>
                                  <input type="text" className={this.state.validationErrors.valorContable ? "form-control is-invalid" : "form-control"} id="valorContable" name="valorContable" placeholder="" value={formData.valorContable === 0 ? '' : formData.valorContable} onChange={this.handleInputFormChange} />
                                  <small className="text-danger">{this.state.validationErrors.valorContable}</small>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* VALOR ALQUILER */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="valor_alquiler">
                              <FormattedMessage id="movilesAbm.render.administracion.valor_alquiler" defaultMessage="Valor de Alquiler"/>{ConfigBusiness.get('moviles.valorAlquiler.obligatorio') === 'true' ? requiredSymbol : ' '}:
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('MOVILES_MODIFICAR_ADMINISTRACION')) ? (
                              <div className="form-control-static col-form-label form-value">{formData.valorAlquiler ? formData.valorAlquiler : ''}</div>
                              ) : (
                                <div>
                                  <input type="text" className={this.state.validationErrors.valorAlquiler ? "form-control is-invalid" : "form-control"} id="valorAlquiler" name="valorAlquiler" placeholder="" value={formData.valorAlquiler === 0 ? '' : formData.valorAlquiler} onChange={this.handleInputFormChange}/>
                                  <small className="text-danger">{this.state.validationErrors.valorAlquiler}</small>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      </div>
                      )}



                      <div className="row">
                        {/* COMENTARIOS LABORALES */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-3 label-control col-form-label" htmlFor="observaciones">
                              <FormattedMessage id="movilesAbm.render.administracion.label_observaciones" defaultMessage="Observaciones"/>:
                            </label>
                            <div className="col-md-9">
                              {this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('MOVILES_MODIFICAR_ADMINISTRACION')) ? (
                              <div className="form-control-static col-form-label form-value">{formData.observaciones ? formData.observaciones : ''}</div>
                              ) : (
                              <div>
                                <textarea className="form-control" id="observaciones" name="observaciones" rows="3" placeholder="" value={formData.observaciones ? formData.observaciones : ''} onChange={this.handleInputFormChange}></textarea>
                              </div>
                              )}
                            </div>
                          </div>
                        </div>

                        {/* SWITCH BUSCAR INFRACCIONES */}
                        <div className="col-md-3">
                          <div className="form-group row">
                            <label className="col-md-6 label-control col-form-label">
                              <FormattedMessage id="movilesAbm.render.buscar_infracciones_movil.label_buscar_infracciones" defaultMessage="Buscar infracciones"/>:
                            </label>
                            <div className="col-md-6 mt-auto">
                              <Switch
                                onChange={ ()=>this.handleBuscarInfracciones(!formData.buscar_infracciones) }
                                checked={formData.buscar_infracciones ? formData.buscar_infracciones : false}
                                id="buscarInfracciones"
                                name="buscarInfracciones"
                                disabled={this.state.props.action === 'VIEW' || !Security.hasPermission('MOVILES_MODIFICAR')}
                                offColor="#FF4961"
                                onColor="#28D094"
                              />
                            </div>
                          </div>
                        </div>
                        {/* BOTON HISTORICO CECO + TARJETA COMBUSTIBLE */}
                        {Security.renderIfHasPermission('MOVILES_ADMINISTRACION_HISTORICO_LISTAR', (
                          <div className="col-md-3">
                            <div className="row justify-content-end">
                              <div className="col-md-4">
                                {this.props.action === 'VIEW' || this.props.action === 'EDIT' ? (
                                <button type="button" className="btn btn-fleet float-right" data-toggle="modal" data-target="#historico_adm_modal" onClick={()=>this.handleSetTipo('HistoricoAdm')}>
                                <i className="la la-search align-middle"></i>
                                <FormattedMessage id="movilesAbm.render.administracion.historico_de_administracion" defaultMessage="Histórico de Administración"/>
                                </button>
                                ) : (
                                <div style={{display:'none'}}></div>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}

                      </div>

                    </div>
                  </div>
                </div>


                <div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body">
                      <h4 className="form-section mt-2">
                        <i className="la la-cog"></i>
                        <FormattedMessage id="movilesAbm.render.dimensiones.header_dimensiones" defaultMessage="Dimensiones"/>
                      </h4>
                      <div className="row">
                        {/* PESO CARGA TOTAL AUTORIZADO */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-6 label-control col-form-label" htmlFor="peso_carga_total_autorizado">
                              <FormattedMessage id="movilesAbm.render.dimensiones.peso_carga_total_autorizado" defaultMessage="Peso Carga Total Autorizado (kg)"/>{ConfigBusiness.get('moviles.pesoCargaTotalAutorizado.obligatorio') === 'true' ? requiredSymbol : ' '}:
                            </label>
                            <div className="col-md-6">
                              {this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('MOVILES_MODIFICAR_DIMENSIONES')) ? (
                              <div className="form-control-static col-form-label form-value">{formData.pesoCargaTotalAutorizado ? formData.pesoCargaTotalAutorizado : ''}</div>
                              ) : (
                                <div>
                                  <input type="text" className={this.state.validationErrors.pesoCargaTotalAutorizado ? "form-control is-invalid" : "form-control"} id="pesoCargaTotalAutorizado" name="pesoCargaTotalAutorizado" placeholder="" value={formData.pesoCargaTotalAutorizado === 0 ? '' : formData.pesoCargaTotalAutorizado} onChange={this.handleInputFormChange} />
                                  <small className="text-danger">{this.state.validationErrors.pesoCargaTotalAutorizado}</small>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* PESO CARGA MAXIMA */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-6 label-control col-form-label" htmlFor="peso_carga_maxima">
                              <FormattedMessage id="movilesAbm.render.dimensiones.peso_carga_maxima" defaultMessage="Peso Carga Máxima (kg)"/>{ConfigBusiness.get('moviles.pesoCargaMaxima.obligatorio') === 'true' ? requiredSymbol : ' '}:
                            </label>
                            <div className="col-md-6">
                              {this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('MOVILES_MODIFICAR_DIMENSIONES')) ? (
                              <div className="form-control-static col-form-label form-value">{formData.pesoCargaMaxima ? formData.pesoCargaMaxima : ''}</div>
                              ) : (
                                <div>
                                  <input type="text" className={this.state.validationErrors.pesoCargaMaxima ? "form-control is-invalid" : "form-control"} id="pesoCargaMaxima" name="pesoCargaMaxima" placeholder="" value={formData.pesoCargaMaxima === 0 ? '' : formData.pesoCargaMaxima} onChange={this.handleInputFormChange} />
                                  <small className="text-danger">{this.state.validationErrors.pesoCargaMaxima}</small>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        {/* VOLUMEN AREA CARCA */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-6 label-control col-form-label" htmlFor="volumen_area_carga">
                              <FormattedMessage id="movilesAbm.render.dimensiones.volumen_area_carga" defaultMessage="Volumen Área de Carga (m3)"/>{ConfigBusiness.get('moviles.volumenAreaCarga.obligatorio') === 'true' ? requiredSymbol : ' '}:
                            </label>
                            <div className="col-md-6">
                              {this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('MOVILES_MODIFICAR_DIMENSIONES')) ? (
                              <div className="form-control-static col-form-label form-value">{formData.volumenAreaCarga ? formData.volumenAreaCarga : ''}</div>
                              ) : (
                                <div>
                                  <input type="text" className={this.state.validationErrors.volumenAreaCarga ? "form-control is-invalid" : "form-control"} id="volumenAreaCarga" name="volumenAreaCarga" placeholder="" value={formData.volumenAreaCarga === 0 ? '' : formData.volumenAreaCarga} onChange={this.handleInputFormChange} />
                                  <small className="text-danger">{this.state.validationErrors.volumenAreaCarga}</small>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* CANTIDAD COMPARTIMENTOS */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-6 label-control col-form-label" htmlFor="cantidad_compartimentos">
                              <FormattedMessage id="movilesAbm.render.dimensiones.cantidad_compartimentos" defaultMessage="Cantidad Compartimentos"/>{ConfigBusiness.get('moviles.cantidadCompartimentos.obligatorio') === 'true' ? requiredSymbol : ' '}:
                            </label>
                            <div className="col-md-6">
                              {this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('MOVILES_MODIFICAR_DIMENSIONES')) ? (
                              <div className="form-control-static col-form-label form-value">{formData.cantidadCompartimentos ? formData.cantidadCompartimentos : ''}</div>
                              ) : (
                                <div>
                                  <input type="text" className={this.state.validationErrors.cantidadCompartimentos ? "form-control is-invalid" : "form-control"} id="cantidadCompartimentos" name="cantidadCompartimentos" placeholder="" value={formData.cantidadCompartimentos === 0 ? '' : formData.cantidadCompartimentos} onChange={this.handleInputFormChange} />
                                  <small className="text-danger">{this.state.validationErrors.cantidadCompartimentos}</small>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        {/* ALTURA ESPACIO CARGA */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-6 label-control col-form-label" htmlFor="altura_espacio_carga">
                              <FormattedMessage id="movilesAbm.render.dimensiones.altura_espacio_carga" defaultMessage="Altura Espacio de Carga (mts)"/>{ConfigBusiness.get('moviles.alturaEspacioCarga.obligatorio') === 'true' ? requiredSymbol : ' '}:
                            </label>
                            <div className="col-md-6">
                              {this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('MOVILES_MODIFICAR_DIMENSIONES')) ? (
                              <div className="form-control-static col-form-label form-value">{formData.alturaEspacioCarga ? formData.alturaEspacioCarga : ''}</div>
                              ) : (
                                <div>
                                  <input type="text" className={this.state.validationErrors.alturaEspacioCarga ? "form-control is-invalid" : "form-control"} id="alturaEspacioCarga" name="alturaEspacioCarga" placeholder="" value={formData.alturaEspacioCarga === 0 ? '' : formData.alturaEspacioCarga} onChange={this.handleInputFormChange} />
                                  <small className="text-danger">{this.state.validationErrors.alturaEspacioCarga}</small>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {/* ANCHO ESPACIO CARGA */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-6 label-control col-form-label" htmlFor="ancho_espacio_carga">
                              <FormattedMessage id="movilesAbm.render.dimensiones.ancho_espacio_carga" defaultMessage="Ancho Espacio de Carga (mts)"/>{ConfigBusiness.get('moviles.anchoEspacioCarga.obligatorio') === 'true' ? requiredSymbol : ' '}:
                            </label>
                            <div className="col-md-6">
                              {this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('MOVILES_MODIFICAR_DIMENSIONES')) ? (
                              <div className="form-control-static col-form-label form-value">{formData.anchoEspacioCarga ? formData.anchoEspacioCarga : ''}</div>
                              ) : (
                                <div>
                                  <input type="text" className={this.state.validationErrors.anchoEspacioCarga ? "form-control is-invalid" : "form-control"} id="anchoEspacioCarga" name="anchoEspacioCarga" placeholder="" value={formData.anchoEspacioCarga === 0 ? '' : formData.anchoEspacioCarga} onChange={this.handleInputFormChange} />
                                  <small className="text-danger">{this.state.validationErrors.anchoEspacioCarga}</small>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {/* LONGITUD ESPACIO CARGA */}
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-6 label-control col-form-label" htmlFor="longitud_espacio_carga">
                              <FormattedMessage id="movilesAbm.render.dimensiones.longitud_espacio_carga" defaultMessage="Longitud Espacio de Carga (mts)"/>{ConfigBusiness.get('moviles.longitudEspacioCarga.obligatorio') === 'true' ? requiredSymbol : ' '}:
                            </label>
                            <div className="col-md-6">
                              {this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('MOVILES_MODIFICAR_DIMENSIONES')) ? (
                              <div className="form-control-static col-form-label form-value">{formData.longitudEspacioCarga ? formData.longitudEspacioCarga : ''}</div>
                              ) : (
                                <div>
                                  <input type="text" className={this.state.validationErrors.longitudEspacioCarga ? "form-control is-invalid" : "form-control"} id="longitudEspacioCarga" name="longitudEspacioCarga" placeholder="" value={formData.longitudEspacioCarga === 0 ? '' : formData.longitudEspacioCarga} onChange={this.handleInputFormChange} />
                                  <small className="text-danger">{this.state.validationErrors.longitudEspacioCarga}</small>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group row">
                            <label className="col-md-6 label-control col-form-label" htmlFor="tipologia_posicion">
                              <FormattedMessage id="movilesAbm.render.dimensiones.tipologia_posicion" defaultMessage="Diagrama de Posicion"/>:
                            </label>
                            <div className="col-md-6">
                              {this.props.action === 'VIEW' || (this.props.action === 'EDIT' && !Security.hasPermission('MOVILES_MODIFICAR_DIMENSIONES')) ? (
                              <div className="form-control-static col-form-label form-value">{this.state.formData.tipologia_posicion ? this.state.formData.tipologia_posicion.identificador : null}</div>
                              ) : (
                                <div>
                                  <Select
                                  id="tipologia_posicion"
                                  name="tipologia_posicion"
                                  placeholder={!state.cebesLoading ? this.props.intl.formatMessage({ id: 'movilesAbm.render.general_data.placeholder_tipologia', defaultMessage: 'Seleccione' }) : ''}
                                  options={this.state.tipologias}
                                  valueKey='id'
                                  labelKey='identificador'
                                  value={tipologia ? { id: tipologia.id, identificador: tipologia.identificador } : this.state.formData.tipologia_posicion}
                                  onChange={(e) => this.handleFormChangeTipologia("tipologia_posicion", e)}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                {this.state.valores_dinamicos.length > 0 ?
                  <div className="card pull-up">
                    <div className="card-content">
                      <div className="card-body">
                        <h4 className="form-section mt-2">
                          <i className="la la-cog"></i>
                          <FormattedMessage id="movilesAbm.render.caracteristicas.header_caracteristicas" defaultMessage="Características"/>
                        </h4>
                        {this.state.valores_dinamicos
                          .map((atributoDinamico, index)  => (
                          (atributoDinamico && atributoDinamico.activo ) &&
                          <div className="row" key={index}>
                            <div className="col-md-6">
                              <div className="form-group row">
                                  <AtributoDinamicoInstancia
                                    movil={formData.movil}
                                    className=""
                                    disabled={this.props.action === 'VIEW'}
                                    handleChange={(name, value) => this.handleChangeAtributoDinamico(index, name, value)}
                                    atributo={atributoDinamico}
                                  />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                : ''}
								<div className="card pull-up">
									<div className="card-content">
										<div className="card-body" id="container-direccion">
											<h4 className="form-section mt-2">
												<i className="ft-map-pin"></i> <FormattedMessage id="movilesAbm.render.lugar_de_guarda.header_lugar_de_guarda" defaultMessage="Lugar de guarda"/>
											</h4>
											<div className="form-group row">
	                      <label className="col-md-8 label-control col-form-label">
													<FormattedMessage id="movilesAbm.render.lugar_de_guarda.label_el_lugar_de_guarda_del_vehiculo_es_la_base_operativa" defaultMessage="El lugar de guarda del vehículo es la Base Operativa"/>: {formData.base ? formData.base.descripcion : 'Desconocida'}
												</label>
	                      <div className="col-md-4 mt-auto">
													<Switch
														onChange={this.handleGuardaPersonalizada}
														checked={formData.guardaEnBase ? formData.guardaEnBase : false}
														id="guardaEnBase"
														name="guardaEnBase"
														disabled={this.state.props.action === 'VIEW' || !Security.hasPermission('MOVILES_MODIFICAR_LUGAR_GUARDA')}
														offColor="#FF4961"
														onColor="#28D094"
													/>
	                      </div>
	                    </div>
                      {!this.state.formData.guardaEnBase &&
                        <div className='form-group row'>
                          <div className="col-md-12">
                            <DireccionAutocompletar
                              disabled={this.state.props.action === 'VIEW' || !Security.hasPermission('MOVILES_MODIFICAR_LUGAR_GUARDA')}
                              action={this.props.action}
                              cambioDireccion={this.handleChangeDireccion}
                              formulario={state.direccionForm}
                              camposObligatorios={["latitud", "longitud"]}
                              camposHidden={["codigoPostal"]}
                              crearProvincias={true}
                              crearLocalidades={true}
                              localidadString={true}
                            />
                          </div>
                        </div>
                      }
                      <div className="row">
                        <div className="col-md-6">
                        </div>
                        {/* BOTON HISTORICO CHOFERES */}
                        {Security.renderIfHasPermission('MOVILES_GUARDA_HISTORICO_LISTAR', (
                        <div className="col-md-6">
                          <div className="form-group row">
                            <div className="col-md-12">
                              {this.props.action === 'VIEW' || this.props.action === 'EDIT' ? (
                              <button type="button" className="btn btn-fleet float-right" data-toggle="modal" data-target="#guarda_modal" onClick={()=>this.handleSetTipo('guarda')}>
                              <i className="la la-search align-middle"></i> <FormattedMessage id="movilesAbm.render.lugar_de_guarda.button_historico_de_lugares_de_guarda" defaultMessage="Histórico de Lugares de Guarda"/>
                              </button>
                              ) : (
                              <div style={{display:'none'}}></div>
                              )}
                            </div>
                          </div>
                        </div>
                        ))}
                      </div>
										</div>
									</div>
								</div>

								<div className="card pull-up">
									<div className="card-content">
										<div className="card-body" id="container-direccion">
											<h4 className="form-section mt-2">
												<i className="la la-truck"></i> <FormattedMessage id="movilesAbm.render.equipamiento_asociado.header_equipamiento_asociado" defaultMessage="Equipamiento Asociado"/>:
											</h4>

			                <div className="row">
													{/* Equipamiento */}
			                    <div className="col-md-12">
			                        <div className="form-group row">
																	<label className="col-md-1 label-control col-form-label" htmlFor="equipamiento">
																	</label>
			                            {this.props.action === 'VIEW' || !Security.hasPermission('MOVILES_MODIFICAR_EQUIPAMIENTO') ? (
			                                this.state.formData.equipamientos && this.state.formData.equipamientos.length ? (
                                  <div className="col-md-offset-1 col-md-10">
                                      <table className="table table-bordered">
                                          <thead className="thead-fleet equipamientos">
                                              <tr>
																									<th scope="col"><FormattedMessage id="movilesAbm.render.equipamiento_asociado.table_column_identificador" defaultMessage="Identificador"/></th>
																									<th scope="col"><FormattedMessage id="movilesAbm.render.equipamiento_asociado.table_column_tipo_de_equipamiento" defaultMessage="Tipo de Equipamiento"/></th>
																									<th scope="col"><FormattedMessage id="movilesAbm.render.equipamiento_asociado.table_column_marca" defaultMessage="Marca"/></th>
																									<th scope="col"><FormattedMessage id="movilesAbm.render.equipamiento_asociado.table_column_modelo" defaultMessage="Modelo"/></th>
																									<th scope="col"><FormattedMessage id="movilesAbm.render.equipamiento_asociado.table_column_opciones" defaultMessage="Opciones"/></th>
                                              </tr>
                                          </thead>
                                          <tbody>
                                          {this.state.formData.equipamientos.map((equipo, index) =>
                                                  <tr key={index}>
																											<td className="align-middle">{equipo.identificador}</td>
																											<td className="align-middle">{equipo.tipo_equipamiento.nombre}</td>
																											<td className="align-middle">{equipo.marca}</td>
																											<td className="align-middle">{equipo.modelo}</td>
                                                      <td className="align-middle">
																												<Link to={'/equipamiento/' + equipo.id}><i className="fa fa-eye"/></Link>
																											</td>
                                                  </tr>
                                          )}
                                          </tbody>
                                      </table>
                                  </div>
																		) : (<p style={{marginTop:'10px'}}><FormattedMessage id="movilesAbm.render.equipamiento_asociado.information_no_cuenta_con_equipamientos" defaultMessage="No cuenta con equipamientos"/></p>)
	                                ) :
	                                (
			                            <div className="col-md-10">

			                                <div className="form-group">
			                                    <select multiple="multiple" size="10" ref="duallistboxequipamientos" value={formData.equipamientos} readOnly>
			                                        {this.state.equipamientosDisponibles.map(function(equipamiento) {
			                                        return <option key={equipamiento.id} value={equipamiento.id}>[{equipamiento.identificador}] {equipamiento.marca} {equipamiento.modelo}</option>;
			                                        })}
			                                    </select>
			                                </div>
			                            </div>
			                            )}
			                        </div>
			                    </div>
			                </div>
										</div>
								</div>
						</div>


            {(this.props.action === 'ADD' || this.props.action !== 'ADD' && this.state.formData.id) && (
                <MovilesAbmGPS movil={this.state.formData} action={this.props.action} callbackUpdate={this.handleGpsChange.bind(this)}></MovilesAbmGPS>
            )}

                {(this.props.action === 'ADD' || this.props.action !== 'ADD' && this.state.formData.id) && (
                <MovilesAbmAdjuntos movil={this.state.formData} action={this.props.action} callbackUpdate={this.handleAdjuntosChange.bind(this)}></MovilesAbmAdjuntos>
                )}

                {!this.props.hideBack && (
                <div className="card pull-up">
                  <div className="card-content">
                    <div className="card-body">
                      <div className="text-cd text-right">
                        {this.props.action !== 'VIEW' && (
                        <button
                          type="submit"
                          className="btn btn-primary mr-1"
                          disabled={
                              !validationState ||
                              ((!this.state.formData.guardaEnBase && !this.state.formData.direccionValid) ||
                                  ConfigBusiness.get('moviles.trabajaConDivisiones.habilitado') === 'true' ? (this.state.formData.division ? false : true) : false)
                          }
                        >
                          <i className="fa fa-check-circle"></i><FormattedMessage id="movilesAbm.finish_button.guardar" defaultMessage=" Guardar"/>
                        </button>
                        )}
                        <button type="button" className="btn btn-danger" onClick={this.handleCancel.bind(this)}>
                          <i className="fa fa-times-circle"></i> {this.props.action === 'VIEW' ? this.props.intl.formatMessage({ id: 'movilesAbm.finish_button.volver', defaultMessage: 'Volver' }) : this.props.intl.formatMessage({ id: 'movilesAbm.finish_button.cancelar', defaultMessage: 'Cancelar' })}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                )}
              </div>
            </form>
					</div>
				</div>

        {Security.renderIfHasPermission('MOVILES_CHOFERES_HISTORICO_LISTAR', (
        <div className="modal fade" tabIndex="-1" id="choferes_modal" role="dialog" aria-labelledby="myModalLabel">
          <div className="modal-xl modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header bg-fleet">
                <h4 className="modal-title text-white" id="myModalLabel">{this.props.intl.formatMessage({ id: 'movilesAbm.choferes_modal.volver.historico_de_choferes_del_movil', defaultMessage: 'Histórico de choferes del móvil' })+': ' + formData.dominio}</h4>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
              </div>
              <div className="modal-body modal-mh ovf-x-hidden">
                {
                  this.state.tipoModal === 'choferes' && <ChoferesGrid movilId={this.state.props.match.params.id}></ChoferesGrid>
                }
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-default btn-fleet" data-dismiss="modal"><FormattedMessage id="movilesAbm.choferes_modal.finish_button.ok" defaultMessage="Ok"/></button>
              </div>
            </div>
          </div>
        </div>
        ))}

        {Security.renderIfHasPermission('MOVILES_ADMINISTRACION_HISTORICO_LISTAR', (
        <div className="modal fade" tabIndex="-1" id="historico_adm_modal" role="dialog" aria-labelledby="myModalLabel">
          <div className="modal-xl modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header bg-fleet">
                <h4 className="modal-title text-white" id="myModalLabel">{this.props.intl.formatMessage({ id: 'movilesAbm.historico_adm_modal.volver.historico_de_cecos_del_movil', defaultMessage: 'Histórico de Administración' })+': ' + formData.dominio}</h4>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
              </div>
              <div className="modal-body modal-mh ovf-x-hidden">
                {
                this.state.tipoModal === 'HistoricoAdm' && <HistoricoAdmGrid movilId={this.state.props.match.params.id} movilDominio={this.state.formData.dominio}/>
                }
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-default btn-fleet" data-dismiss="modal"><FormattedMessage id="movilesAbm.historico_adm_modal.finish_button.ok" defaultMessage="Ok"/></button>
              </div>
            </div>
          </div>
        </div>
        ))}

        {Security.renderIfHasPermission('MOVILES_GUARDA_HISTORICO_LISTAR', (
        <div className="modal fade" tabIndex="-1" id="guarda_modal" role="dialog" aria-labelledby="myModalLabel">
          <div className="modal-xl modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header bg-fleet">
                <h4 className="modal-title text-white" id="myModalLabel">{this.props.intl.formatMessage({ id: 'movilesAbm.guarda_modal.volver.historico_de_choferes_del_movil', defaultMessage: 'Histórico de choferes del móvil' })+': ' + formData.dominio}</h4>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
              </div>
              <div className="modal-body modal-mh ovf-x-hidden">
                {
                  this.state.tipoModal === 'guarda' && <LugarDeGuardaGrid movilId={this.state.props.match.params.id}></LugarDeGuardaGrid>
                }
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-default btn-fleet" data-dismiss="modal"><FormattedMessage id="movilesAbm.guarda_modal.finish_button.ok" defaultMessage="Ok"/></button>
              </div>
            </div>
          </div>
        </div>
        ))}

        {Security.renderIfHasPermission('MOVILES_DATOS_GENERALES_HISTORICO_LISTAR', (
          <div className="modal fade" tabIndex="-1" id="datosGenerales_modal" role="dialog" aria-labelledby="myModalLabel">
            <div className="modal-xl modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header bg-fleet">
                  <h4 className="modal-title text-white" id="myModalLabel">{this.props.intl.formatMessage({ id: 'movilesAbm.datosGenerales_modal.volver.datosGenerales_movil', defaultMessage: 'Histórico de D. Generales del móvil' })+': ' + formData.dominio}</h4>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                </div>
                <div className="modal-body modal-mh ovf-x-hidden">
                  {
                    this.state.tipoModal === 'datosGenerales' && <HistoricoDatosGeneralesGrid movilId={this.state.props.match.params.id}></HistoricoDatosGeneralesGrid>
                  }
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-default btn-fleet" data-dismiss="modal"><FormattedMessage id="movilesAbm.datosGenerales_modal.finish_button.ok" defaultMessage="Ok"/></button>
                </div>
              </div>
            </div>
          </div>
        ))}
        <MovilesCambioTipologia
          movil={this.state.formData}
          tipologia_posicion={this.state.tipologiaSelect}
          open={this.state.showModalTipologiaLlanta}
          intl={this.props.intl}
          callbackUpdate={this.closeModalTipologiaLlanta.bind(this)}
          update={this.updateTipologiaLlanta.bind(this)}
        >
        </MovilesCambioTipologia>
			</React.Fragment>
		);
	}
}

export default injectIntl(MovilesAbm);
